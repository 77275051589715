import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ConnectDataPrivacyService } from '../../../services/requests/connect-data-privacy/connect-data-privacy.service';
import { FeatureSetEnum, IdentityModel, Metadata, MetadataType, MonitoringStateStatus } from '../../../models/privacy/Identity.model';
import { IDomainServiceBreaches, IDomainServiceDetails, IExposureGrouppedByService, IServiceBreachesImpacting, PrivacyExposure, PrivacyExposureCouter, } from '../../../models/privacy/PrivacyExposure.model';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { ValuesService } from '../../../values/values.service';
import { PrivacyValuesService, SummaryType, PrivacyEvents } from '../../../values/privacy.values.service';
import { MessageService } from '../../core/message.service';
import { RemediationServiceDetails } from '../../../../common/models/privacy/Remediation.model';
import { IssueActionStatus, PrivacyIssue, PrivacyIssueAction, IExposureIssue, PrivacyIssueType, IssuesObjectInfo } from '../../../../common/models/privacy/PrivacyIssue.model';
import { IMarkToUpdateFootprintServiceState, IPrivacyFootPrintIssuesType, IPrivacyFootprintServiceState } from '../../../../common/models/privacy/PrivacyFootprint.model';
import { IPrivacyScore } from '../../../../common/models/privacy/PrivacyScore.model';
import { AppsConfigService } from '../../../../common/config/apps.config.service';
import { ModalRoutelessService } from '../../../components/ui/ui-modal-routeless/modal.routeless.service';
import { FeedbackModel } from '../../../../common/models/privacy/Feedback.model';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';

export enum ServiceState {
    WAITING = 'waiting',
    INPROGRESS = 'in_progress',
    DONE = 'done'
}

export interface IPaginationObject {
    limit: number;
    offset: number;
}

@Injectable({
    providedIn: 'root'
})
export class PrivacyService {

    has500Error = false;
    hasDIPSubscription = true;

    //* lista de subscriberi pt obiecte -> requesturile se executa o sg data (s-a rezolvat si problema requesturilor simultane)
    private readonly onListProvidedIdentity$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistExposure$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistFootprintServices$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistFootprintServiceExposures$: IPrivacyFootprintServiceState = {};
    private readonly onlistFootprintDetailedServices$: IPrivacyFootprintServiceState = {};
    private readonly onlistBreaches$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistImpersonations$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    private readonly onlistEvents$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListPrivacyScore$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListActivityExposureSummary$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListActivityBreachesSummary$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListActivityImpersonationsSummary$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListIssueObject$: {[key: string]: BehaviorSubject<string>} = {};

    providedIdentity: IdentityModel;
    parkedIdentity: boolean = false;
    deletedIdentity: boolean = false;
    digitalFootprintZeroState: any;
    exposureDigitalFootprint: any;
    exposureDigitalFootprintGroupped: Array<IExposureGrouppedByService>;
    digitalFootprintServices: Array<IDomainServiceDetails>;
    footprintServiceDetails: IDomainServiceDetails;
    footprintServiceExposures: { [key: string]: Array<IExposureIssue> } = {};
    footprintServiceBreaches: { [key: string]: Array<IDomainServiceBreaches | IServiceBreachesImpacting> } = {};
    remediation: { [key: string]: RemediationServiceDetails };
    exposureDashboard: { [key: string]: PrivacyExposure[] };
    exposureDashboardLid = {
        list: [],
        count: 0
    };

    privacyScore: IPrivacyScore;
    exposureCountDigitalFootprint: PrivacyExposureCouter;
    exposureCountDigitalFootprintGroupped = 0;
    totalCountDashboard: number;
    exposureCountDashboard: PrivacyExposureCouter;

    privacyModules = {};
    privacyActiveModules = [];

    private _userPrivacyModules = new Set();

    private breachesObj = {} as IssuesObjectInfo;
    private impersonationsObj = {} as IssuesObjectInfo;
    private issueObject: {[key: string]: PrivacyIssue} = {};

    private readonly paginationObjectImpersonations: IPaginationObject = {
        limit: 10,
        offset: 0
    };
    private readonly paginationObjectBreaches: IPaginationObject = {
        limit: 10,
        offset: 0
    };

    events: any = {};
    varsEvents = {
        count: 10,
        offset: 0
    };

    activityExposureSummary: any;
    activityBreachesSummary: any;
    activityImpersonationsSummary: any;

    getEntriesErrorIds = new Set([
        this.valuesService.connectErrorIds.DATABASE_ERROR,
        this.valuesService.connectErrorIds.COMMUNICATION_ERROR
    ]);


    private markToUpdate_providedIdentity = true;
    private markToUpdate_exposureData = true;
    private markToUpdate_events = true;
    private markToUpdate_breaches = true;
    private markToUpdate_impersonations = true;
    private markToUpdateIssueObject: {[key: string]: boolean} = {};

    private markToUpdate_privacyScore = true;
    private markToUpdate_activityExposureSummary = true;
    private markToUpdate_activityBreachesSummary = true;
    private markToUpdate_activityImpersonationsSummary = true;
    private markToUpdateFootprintServices = true;
    private markToUpdateFootPrintServiceDetails: IMarkToUpdateFootprintServiceState = {};
    private markToUpdateFootPrintServiceExposures: IMarkToUpdateFootprintServiceState = {};

    constructor(
        readonly connectDataPrivacyService : ConnectDataPrivacyService,
        readonly utilsService              : UtilsCommonService,
        readonly valuesService             : ValuesService,
        readonly router                    : Router,
        readonly messageService            : MessageService,
        private readonly  modalRoutelessService : ModalRoutelessService,
        public  privacyValuesService       : PrivacyValuesService,
        private readonly appsConfigService : AppsConfigService,
        private readonly subscriptionsService: SubscriptionsService
    ) { }

    // -- get objects from memory or connect
    listProvidedIdentity(): Observable<any> {
        if (!this.markToUpdate_providedIdentity || !this.appsConfigService.showApp(this.valuesService.appDIP)) {
            return of(this.providedIdentity);
        }
        this.has500Error = false;
        this.deletedIdentity = false;
        this.hasDIPSubscription = true;

        if (this.onListProvidedIdentity$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListProvidedIdentity$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }
        this.onListProvidedIdentity$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getIdentity()
            .pipe(
                map(
                    resp => {
                        this.onListProvidedIdentity$.next(ServiceState.DONE);
                        this.providedIdentity = resp;
                        this.computeAvailableModulesByFeatureSet();
                        this.parkedIdentity = !!this.providedIdentity?.parking_details?.active;
                        this.markToUpdate_providedIdentity = false;
                        this.onListProvidedIdentity$.next(this.valuesService.processServiceState.DONE);
                        if (resp["parking_details"]) {
                            if (resp["parking_details"].active) {
                                this.parkedIdentity = true;
                                this.messageService.sendMessage(PrivacyEvents.PARKED_IDENTITY, {parkedIdentity: true, modalType: this.modalRoutelessService.getIsModalOpened()});
                            } else {
                                // imediat dupa ce identitatea a fost unparked
                                const oldParkedIdentity = JSON.parse(JSON.stringify(this.parkedIdentity));
                                this.parkedIdentity = false;
                                if (oldParkedIdentity) {
                                    this.messageService.sendMessage(PrivacyEvents.PARKED_IDENTITY, {parkedIdentity: false});
                                }
                            }

                        }
                        return this.providedIdentity;
                    }
                ),
                catchError(err => {
                    this.markToUpdate_providedIdentity = true;
                    this.onListProvidedIdentity$.next(this.valuesService.processServiceState.DONE);
                    if (err.code && err.code === this.privacyValuesService.error_codes.no_identity_found) { //no identity found
                        this.deletedIdentity = true;
                        this.parkedIdentity = false;
                        this.messageService.sendMessage(PrivacyEvents.DELETED_IDENTITY, {deletedIdentity: true});
                        this.markToUpdate_providedIdentity = false;
                        this.providedIdentity = undefined;
                        return of(this.providedIdentity);
                    } else if (err.code && err.code === this.privacyValuesService.error_codes.expired_subscription) { //no subscription
                        this.markToUpdate_providedIdentity = false;
                        this.providedIdentity = undefined;
                        this.hasDIPSubscription = false;
                        return of(this.providedIdentity);
                    } else {
                        if(err.redirect500) {
                            this.has500Error = true;
                        }
                        throw err;
                    }
                })
            );


    }

    listServicesLid(): Observable<any> {
        if (!this.markToUpdateFootprintServices || this.getParkedIdentityStatus()) {
            return of(this.digitalFootprintServices);
        }

        if (this.onlistFootprintServices$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistFootprintServices$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }
        this.onlistFootprintServices$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getServicesLid()
        .pipe(
            map((resp: any) => {
                this.digitalFootprintServices = resp.services ? resp.services : [];
                this.markToUpdateFootprintServices = false;
                this.digitalFootprintZeroState = resp?.zero_state ?? {};
                this.onlistFootprintServices$.next(this.valuesService.processServiceState.DONE);
                return of(this.digitalFootprintServices);
            }),
            catchError((err) => {
                if (!this.checkParkedIdentity(err, false)) {
                    this.markToUpdateFootprintServices = true;
                } else {
                    this.markToUpdateFootprintServices = false;
                }
                this.onlistFootprintServices$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    listServiceDetailsLid(serviceId: string, lang: string): Observable<any> {
        if(!this.onlistFootprintDetailedServices$.hasOwnProperty(serviceId)) {
            this.onlistFootprintDetailedServices$[serviceId] = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
        }

        if(!this.markToUpdateFootPrintServiceDetails.hasOwnProperty(serviceId)) {
            this.markToUpdateFootPrintServiceDetails[serviceId] = true;
        }

        if(!this.markToUpdateFootPrintServiceDetails[serviceId]) {
            return of(this.footprintServiceDetails);
        }

        if (this.onlistFootprintDetailedServices$[serviceId].value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistFootprintDetailedServices$[serviceId].asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }
        this.onlistFootprintDetailedServices$[serviceId].next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getServiceDetailsLid(serviceId, lang)
        .pipe(
            map((response: IDomainServiceDetails) => {
                this.onlistFootprintDetailedServices$[serviceId].next(this.valuesService.processServiceState.DONE);
                this.markToUpdateFootPrintServiceDetails[serviceId] = false;
                if(this.footprintServiceDetails?.service_id !== serviceId) {
                    this.markToUpdateFootPrintServiceDetails[this.footprintServiceDetails?.service_id] = true;
                }
                this.footprintServiceDetails = response;
                return of(this.footprintServiceDetails);
            }),
            catchError((err) => {
                this.markToUpdateFootPrintServiceExposures[serviceId] = !this.checkParkedIdentity(err, false);
                this.onlistFootprintDetailedServices$[serviceId].next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    listServiceIssuesLid(serviceId: string, type: string): Observable<any> {
        if(!this.onlistFootprintServiceExposures$.hasOwnProperty(serviceId)) {
            this.onlistFootprintServiceExposures$[serviceId + type] = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
        }

        if(!this.markToUpdateFootPrintServiceExposures.hasOwnProperty(serviceId + type)) {
            this.markToUpdateFootPrintServiceExposures[serviceId + type] = true;
            if(type === IPrivacyFootPrintIssuesType.BREACH) {
                this.footprintServiceBreaches[serviceId] = new Array<IDomainServiceBreaches>();
            } else {
                this.footprintServiceExposures[serviceId] = new Array<IExposureIssue>();
            }
        }

        if(!this.markToUpdateFootPrintServiceExposures[serviceId + type]) {
            return of(type === IPrivacyFootPrintIssuesType.EXPOSURE ? this.footprintServiceExposures[serviceId] : this.footprintServiceBreaches[serviceId]);
        }

        if (this.onlistFootprintServiceExposures$[serviceId + type].value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistFootprintServiceExposures$[serviceId + type].asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }
        this.onlistFootprintServiceExposures$[serviceId + type].next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getIssuesByServiceLid(serviceId, type)
        .pipe(
            map((response) => {
                this.onlistFootprintServiceExposures$[serviceId + type].next(this.valuesService.processServiceState.DONE);
                this.markToUpdateFootPrintServiceExposures[serviceId + type] = false;
                if(type === IPrivacyFootPrintIssuesType.EXPOSURE) {
                    this.footprintServiceExposures[serviceId] = response;
                    return of(this.footprintServiceExposures[serviceId]);
                } else {
                    this.footprintServiceBreaches[serviceId] = response;
                    return of(this.footprintServiceBreaches[serviceId]);
                }
            }),
            catchError((err) => {
                this.onlistFootprintServiceExposures$[serviceId + type].next(this.valuesService.processServiceState.DONE);
                this.markToUpdateFootPrintServiceExposures[serviceId + type] = !this.checkParkedIdentity(err, false);
                throw err;
            })
        );
    }

    listExposureData(): Observable<any> {
        if (!this.markToUpdate_exposureData) {
            this.has500Error = false;
            return of(this.exposureDashboard);
        }

        if (this.onlistExposure$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistExposure$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }
        this.onlistExposure$.next(this.valuesService.processServiceState.INPROGRESS);

        return this.connectDataPrivacyService.getExposureLid()
        .pipe(
            map(
                resp => {
                    this.exposureDashboardLid.list = [];
                    this.exposureDashboardLid.count = resp.count;

                    if (!Array.isArray(resp?.exposure)) {
                        this.markToUpdate_exposureData = true;
                        this.onlistExposure$.next(this.valuesService.processServiceState.DONE);
                        return of(this.exposureDashboard);
                    }

                    for (const exposure of resp.exposure) {
                        if (exposure.exposure_type !== MetadataType.REFERENCES
                            && exposure.exposure_type !== MetadataType.USER_IDS
                            && exposure.exposure_type !== MetadataType.LANGUAGES
                            && exposure.exposure_type !== MetadataType.IMAGES) {
                                this.exposureDashboardLid.list.push(exposure);
                        }
                        exposure['skipped'] = false;
                    }
                    this.markToUpdate_exposureData = false;
                    this.onlistExposure$.next(this.valuesService.processServiceState.DONE);
                    return of(this.exposureDashboard);
                }
            ),
            catchError(err => {
                this.markToUpdate_exposureData = !this.checkParkedIdentity(err, false);
                this.onlistExposure$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Method that gets an issues based on issue id
     * @param {string} issue id
     * @param {PrivacyIssueType} the type of the issue
     * @param {boolean} if it will clear the value
     * @returns {Observable}
     */
    public listSingleIssue(issueId: string, type?: PrivacyIssueType, clear?: boolean): Observable<any> {
        if(!this.onListIssueObject$[issueId]) {
            this.onListIssueObject$[issueId] = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
            this.markToUpdateIssueObject[issueId] = true;
        }

        if (!this.markToUpdateIssueObject[issueId]) {
            this.has500Error = false;
            return of(this.issueObject[issueId]);
        }
        if (!type) {
            type = PrivacyIssueType.BREACH;
            clear = true;
        }

        if (this.onListIssueObject$[issueId]?.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListIssueObject$[issueId].asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        if(!this.onListIssueObject$[issueId]) {
            this.onListIssueObject$[issueId] = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
            this.markToUpdateIssueObject[issueId] = true;
        }

        this.onListIssueObject$[issueId].next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getSingleIssue(issueId, type, clear)
        .pipe(
            map(resp => {
                this.issueObject[issueId] = resp.issues[0];

                this.markToUpdateIssueObject[issueId] = false;
                this.onListIssueObject$[issueId].next(this.valuesService.processServiceState.DONE);

                return this.issueObject[issueId];
            }),
            catchError(err => {
                if (err?.redirect500) {
                    this.has500Error = true;
                } else if (!this.checkParkedIdentity(err, false)
                            && !this.getEntriesErrorIds.has(err?.code)
                            && err?.status !== this.valuesService.requestStatuses.ERROR_STATUS) {
                    this.markToUpdateIssueObject[issueId] = true;
                }

                this.onListIssueObject$[issueId].next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Method that gets the list of breaches
     * @param {boolean} for pagination - if is next page (or click on loard more btn)
     * @returns {Observable}
     */
    public listBreaches(isLoadMore?: boolean): Observable<any> {
        if (!this.markToUpdate_breaches && isLoadMore !== true) {
            this.has500Error = false;
            return of(this.breachesObj);
        }

        if (this.onlistBreaches$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistBreaches$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onlistBreaches$.next(this.valuesService.processServiceState.INPROGRESS);
        const paginationObj = this.paginationObjectBreaches;

        return this.connectDataPrivacyService.getIssues(PrivacyIssueType.BREACH, paginationObj.limit, paginationObj.offset, true)
        .pipe(
            map(resp => {
                this.has500Error = false;

                if (!resp) {
                    throw resp;
                }

                this._computeBreachesObj(resp, paginationObj);

                this.markToUpdate_breaches = false;
                this.onlistBreaches$.next(this.valuesService.processServiceState.DONE);
                return of(this.breachesObj);
            }),
            catchError(err => {
                if (err?.redirect500) {
                    this.has500Error = true;
                } else if (!this.checkParkedIdentity(err, false) && !this.getEntriesErrorIds.has(err?.code)
                            && err?.status !== this.valuesService.requestStatuses.ERROR_STATUS) {
                    this.markToUpdate_breaches = true;
                }

                this.onlistBreaches$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    private _computeBreachesObj(resp, paginationObj): void {
        this.breachesObj.zero_state = resp.zero_state;
        this.breachesObj.noOfIssues = resp.count;

        if (resp && (resp.count <= resp.issues.length || !resp.issues.length || (resp.offset + paginationObj.limit) >= resp.count)) {
            this.breachesObj.gotAllIssuesInList = true;
        } else {
            this.breachesObj.gotAllIssuesInList = false;
        }

        for (const issue of resp.issues) {
            issue._internalID = this.utilsService.crc32Convert(issue.issue_id);
        }

        if (!this.breachesObj.listOfIssues || this.breachesObj.listOfIssues.length === 0 || this.markToUpdate_breaches) {
            this.breachesObj.listOfIssues = resp.issues;
        } else {
            this.breachesObj.listOfIssues = this.breachesObj.listOfIssues.concat(resp.issues);
        }

        paginationObj.offset = paginationObj.offset + paginationObj.limit;
    }

    listImpersonations(isLoadMore?: boolean): Observable<any> {
        if (!this.markToUpdate_impersonations && isLoadMore !== true) {
            this.has500Error = false;
            return of(this.impersonationsObj);
        }

        if (this.onlistImpersonations$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistImpersonations$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        const paginationObject = this.paginationObjectImpersonations;
        this.onlistImpersonations$.next(this.valuesService.processServiceState.INPROGRESS);

        return this.connectDataPrivacyService.getIssues(PrivacyIssueType.IMPERSONATION, paginationObject.limit, paginationObject.offset, true)
            .pipe(
                map(resp => {
                    this.has500Error = false;

                    if (!resp) {
                        throw resp;
                    }

                    this._computeImpersonationsList(resp, paginationObject);

                    this.markToUpdate_impersonations = false;
                    this.onlistImpersonations$.next(this.valuesService.processServiceState.DONE);
                    return of(this.impersonationsObj);
                }),
                catchError(err => {
                    if(err?.redirect500) {
                        this.has500Error = true;
                    } else if (!this.checkParkedIdentity(err, false)
                                && !this.getEntriesErrorIds.has(err?.code)
                                && err?.status !== this.valuesService.requestStatuses.ERROR_STATUS) {
                        this.markToUpdate_impersonations = true;
                    }

                    this.onlistImpersonations$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );

    }

    /**
     * Method that gets the list of breaches
     * @param {object} the response ofthe impersonations list request
     * @param {IPaginationObject} the pagination object
     * @returns {nothing}
     */
    private _computeImpersonationsList(issuesResponse: any, paginationObject: IPaginationObject): void {
        this.impersonationsObj.zero_state = issuesResponse.zero_state;
        this.impersonationsObj.listOfIssues = issuesResponse.count;

        if (issuesResponse && (issuesResponse.count <= issuesResponse.issues.length || !issuesResponse.issues.length || (issuesResponse.offset + paginationObject.limit) >= issuesResponse.count)) {
            this.impersonationsObj.gotAllIssuesInList = true;
        } else {
            this.impersonationsObj.gotAllIssuesInList = false;
        }

        for (const issue of issuesResponse.issues) {
            issue._internalID = this.utilsService.crc32Convert(issue.issue_id);
        }

        if (!this.impersonationsObj.listOfIssues || this.impersonationsObj.listOfIssues.length === 0 || this.markToUpdate_impersonations) {
            this.impersonationsObj.listOfIssues = issuesResponse.issues;
        } else {
            this.impersonationsObj.listOfIssues = this.impersonationsObj.listOfIssues.concat(issuesResponse.issues);
        }

        paginationObject.offset = paginationObject.offset + paginationObject.limit;
    }

    listEventsLid(isLoadMore?: boolean): Observable<any> {
        if (!this.markToUpdate_events && isLoadMore !== true) {
            return of(this.events);
        }

        if (this.onlistEvents$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistExposure$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onlistEvents$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getEventsLid(this.varsEvents.offset, this.varsEvents.count)
        .pipe(
            map(resp => {
                this.has500Error = false;

                if (!resp) {
                    throw resp;
                }

                if (resp && resp.length < this.varsEvents.count) {
                    this.events.final = true;
                } else {
                    this.events.final = false;
                }

                if (!this.events.list || this.events.list.length === 0 || this.markToUpdate_events) {
                    this.events.list = resp;
                } else {
                    this.events.list = this.events.list.concat(resp);
                }

                this.varsEvents.offset = this.varsEvents.offset + this.varsEvents.count;

                this.markToUpdate_events = false;
                this.onlistEvents$.next(this.valuesService.processServiceState.DONE);
                return of(this.events);
            }),
            catchError(err => {
                if (err?.redirect500) {
                    this.has500Error = true;
                } else if (!this.checkParkedIdentity(err, false) && !this.getEntriesErrorIds.has(err?.code)
                            && err?.status !== this.valuesService.requestStatuses.ERROR_STATUS) {
                    this.markToUpdate_events = true;
                }

                this.onlistEvents$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    listPrivacyScore(): Observable<any> {
        if (!this.markToUpdate_privacyScore) {
            return of(this.privacyScore);
        }

        if (this.onListPrivacyScore$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListPrivacyScore$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onListPrivacyScore$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getPrivacyScore()
        .pipe(
            map((response: IPrivacyScore) => {
                this.privacyScore = response;
                this.onListPrivacyScore$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdate_privacyScore = false;
                return of(this.privacyScore);
            }),
            catchError(err => {
                this.onListPrivacyScore$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdate_privacyScore = false;
                throw err;
            })
        );
    }

    eventIsIncluded(includedEvent, event) {
        for (const key in includedEvent) {
            if (event[key] !== includedEvent[key]) {
                return false;
            }
        }
        return true;
    }

    undoAction(event): Observable<any> {
        return this.connectDataPrivacyService.undoLidEventStatus(event)
        .pipe(
            map(resp => {
                event.event_undo = false;
                if (Array.isArray(resp)) {
                    this.events.list.unshift(resp[0]);
                }

                if (event.object_type === PrivacyIssueType.IMPERSONATION) {
                    //! trebuie decomentata linia pt a nu mai face list la fiecare refresh de impersonations tab -> cand se face refactorizarea de impersonations
                    //this.updateImpersonationsList();
                    this.updateActivityImpersonationsSummary();
                } else if (event.object_type === PrivacyIssueType.BREACH) {
                    this.updateBreachesList();
                    this.updateActivityBreachesSummary();
                } else {
                    this.updateActivityExposureSummary();
                }
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    listActivityExposureSummary(): Observable<any> {
        if (!this.markToUpdate_activityExposureSummary) {
            this.has500Error = false;
            return of(this.activityExposureSummary);
        }

        //este un request in progress
        if (this.onListActivityExposureSummary$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListActivityExposureSummary$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onListActivityExposureSummary$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getSummary(SummaryType.EXPOSURE)
            .pipe(
                map(
                    resp => {
                        this.has500Error = false;
                        this.onListActivityExposureSummary$.next(ServiceState.DONE);
                        this.activityExposureSummary = resp;
                        this.markToUpdate_activityExposureSummary = false;
                        this.onListActivityExposureSummary$.next(this.valuesService.processServiceState.DONE);
                        return of(this.activityExposureSummary);
                    }
                ),
                catchError(err => {
                    if(err.redirect500) {
                        this.has500Error = true;
                    } else if (!this.checkParkedIdentity(err, false)) {
                        this.markToUpdate_activityExposureSummary = true;
                        this.onListActivityExposureSummary$.next(this.valuesService.processServiceState.DONE);
                    }
                    throw err;
                })
            );

    }

    listActivityBreachesSummary(): Observable<any> {
        if (!this.markToUpdate_activityBreachesSummary) {
            this.has500Error = false;
            return of(this.activityBreachesSummary);
        }

        if (this.onListActivityBreachesSummary$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListActivityBreachesSummary$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onListActivityBreachesSummary$.next(this.valuesService.processServiceState.INPROGRESS);
        const summaryType = SummaryType.BREACH;
        return this.connectDataPrivacyService.getSummary(summaryType)
            .pipe(
                map(
                    resp => {
                        this.has500Error = false;
                        this.onListActivityBreachesSummary$.next(ServiceState.DONE);
                        this.activityBreachesSummary = resp;
                        this.markToUpdate_activityBreachesSummary = false;
                        this.onListActivityBreachesSummary$.next(this.valuesService.processServiceState.DONE);
                        return of(this.activityBreachesSummary);
                    }
                ),
                catchError(err => {
                    if(err.redirect500) {
                        this.has500Error = true;
                    } else if (!this.checkParkedIdentity(err, false)) {
                        this.markToUpdate_activityBreachesSummary = true;
                        this.onListActivityBreachesSummary$.next(this.valuesService.processServiceState.DONE);
                    }
                    throw err;
                })
            );

    }

    listActivityImpersonationsSummary(): Observable<any> {
        if (!this.markToUpdate_activityImpersonationsSummary) {
            this.has500Error = false;
            return of(this.activityImpersonationsSummary);
        }

        if (this.onListActivityImpersonationsSummary$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListActivityImpersonationsSummary$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onListActivityImpersonationsSummary$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getSummary(SummaryType.IMPERSONATION)
            .pipe(
                map(
                    resp => {
                        this.has500Error = false;
                        this.onListActivityImpersonationsSummary$.next(ServiceState.DONE);
                        this.activityImpersonationsSummary = resp;
                        this.markToUpdate_activityImpersonationsSummary = false;
                        this.onListActivityImpersonationsSummary$.next(this.valuesService.processServiceState.DONE);
                        return of(this.activityImpersonationsSummary);
                    }
                ),
                catchError(err => {
                    if (err.redirect500) {
                        this.has500Error = true;
                    } else if (!this.checkParkedIdentity(err, false)) {
                        this.markToUpdate_activityImpersonationsSummary = true;
                        this.onListActivityImpersonationsSummary$.next(this.valuesService.processServiceState.DONE);
                    }
                    throw err;
                })
            );
    }

    /**
     * Method that set status for the taken action
     * @param {PrivacyIssue} current issue
     * @param {PrivacyIssueAction} current action
     * @param {IssueActionStatus} current status of the action
     * @returns {Observable}
     */
    public setActionStatus(issue: PrivacyIssue, action: PrivacyIssueAction, issueStatus: IssueActionStatus): Observable<any> {
        return this.connectDataPrivacyService.setActionStatus(issue.issue_id, action.type, issueStatus, issue.type)
        .pipe(
            map( (res: boolean) => {
                if (res) {
                    for (const issueAction of issue.issue_actions) {
                        if (issueAction.type === action.type) {
                            issueAction.status = issueStatus === IssueActionStatus.UNDO ? IssueActionStatus.NEW : issueStatus;
                        }
                    }
                    this.updateBreachesList();
                }

                return res;
            }),
            catchError(err => {
                if (err.redirect500) {
                    this.has500Error = true;
                } else if (!this.checkParkedIdentity(err, false)) {
                    this.markToUpdate_breaches = true;
                }
                throw err;
            })
        );
    }

    /**
     * Method that sends feedback
     * @param {FeedbackModel} current feedback to be sent
     * @returns {Observable}
     */
    public sendFeedback(feedBackObj: FeedbackModel): Observable<any>  {
        return this.connectDataPrivacyService.submitFeedback(feedBackObj)
        .pipe(
            map((res: boolean) => {
                return res;
            }),
            catchError(err => {
                throw err;
            })
        );
    }


    // -- reset objects
    updateProvidedIdentity() {
        if (this.onListProvidedIdentity$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_providedIdentity = true;
        }
    }

    updateExposureData(loadMore?: boolean, notEvent?: boolean) {
        if (this.onlistExposure$.value !== this.valuesService.processServiceState.INPROGRESS) {
            if ((!loadMore) && notEvent) {
                this.exposureDashboard = {};
            }
            this.markToUpdate_exposureData = true;
        }
    }

    updateServiceExposuresStatus(type: string, issueId: string, serviceId: string): void {
        if (!this.footprintServiceExposures[serviceId]) {
            return;
        }
        for (const exposure of this.footprintServiceExposures[serviceId]) {
                if(exposure.issue_id === issueId) {
                    exposure.confirmation_status = type;
                }
        }
        this.markToUpdateFootPrintServiceExposures[serviceId + PrivacyIssueType.EXPOSURE] = true;
    }

    updateServiceExposuresStatusRejected(issueId: string, serviceId): void {
        this.footprintServiceExposures[serviceId] = this.footprintServiceExposures[serviceId].filter(issue => issue.issue_id !== issueId);
    }

    updateRejectedGroupped(issueId: string): void {
        for (const item of this.exposureDigitalFootprintGroupped) {
            item.issues = item.issues.filter(issue => issue.issue_id !== issueId);
            if (item.issues?.length === 0) {
                const index = this.exposureDigitalFootprintGroupped.indexOf(item);
                this.exposureDigitalFootprintGroupped.splice(index, 1);
            }
        }
    }

    /**
     * Method that updates flag for single issue
     * @param {string} the id of the issue to be retrieved
     * @returns {nothing}
     */
    public updateIssue(issueId: string): void {
        if (this.onListIssueObject$[issueId]?.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateIssueObject[issueId] = true;
        }
    }

    /**
     * Method that updates flag and pagination object for breaches list
     * @returns {nothing}
     */
    public updateBreachesList(): void {
        if (this.onlistBreaches$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_breaches = true;
            this.paginationObjectBreaches.offset = 0;
        }
    }

    /**
     * Method that updates flag and pagination object for impersonations list
     * @returns {nothing}
     */
    public updateImpersonationsList(): void {
        if (this.onlistImpersonations$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_impersonations = true;
            this.paginationObjectImpersonations.offset = 0;
        }
    }

    updateEventsList() {
        if (this.onlistEvents$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_events = true;
            this.varsEvents.offset = 0;
        }
    }

    updateActivityExposureSummary() {
        if (this.onListActivityExposureSummary$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_activityExposureSummary = true;
        }
    }

    updateActivityBreachesSummary() {
        if (this.onListActivityBreachesSummary$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_activityBreachesSummary = true;
        }
    }

    updatePrivacyScore() {
        if (this.onListPrivacyScore$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_privacyScore = true;
        }
    }

    updateActivityImpersonationsSummary() {
        if (this.onListActivityImpersonationsSummary$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_activityImpersonationsSummary = true;
        }
    }

    checkParkedIdentity(resp, callIsMadeFromModal: boolean) {
        if (resp?.code === this.privacyValuesService.error_codes.expired_subscription) {
            this.parkedIdentity = true;
            this.subscriptionsService.updateSubscriptions();
            if (callIsMadeFromModal) {
                this.modalRoutelessService.close(this.valuesService.centralPaths.privacy.path.concat(this.valuesService.centralPaths.privacy.activity.path));
            } else {
                this.router.navigate([this.valuesService.centralPaths.privacy.path.concat(this.valuesService.centralPaths.privacy.activity.path)]);
            }
            return true;
        }
        return false;
    }

    /**
     * Method that gets onboarding process status
     * @returns {string} the status of onboarding
     */
    public getOnboardingStatus(): string {
        const identity = this.getProvidedIdentity();
        if (!identity) {
            return this.privacyValuesService.onboardingStatus.NOT_STARTED;
        } else {
            if (identity.init_done) {
                return this.privacyValuesService.onboardingStatus.DONE;
            } else if (this.privacyValuesService.onboardingSteps.has(identity.onboarding)) {
                return this.privacyValuesService.onboardingStatus.IN_PROGRESS;
            }
        }
    }

    onboardingIsDone() {
        return this.getOnboardingStatus() === this.privacyValuesService.onboardingStatus.DONE;
    }

    // -- return objects

    getParkedIdentityStatus() {
        return this.parkedIdentity;
    }

    /**
     * Method that verifies if identity is parked
     * @returns { MonitoringStateStatus } the status of the identity, if parked (inactive) or not (active)
     */
    public getMonitoringState(): MonitoringStateStatus {
        if (!this.parkedIdentity) {
            return MonitoringStateStatus.ACTIVE;
        } else {
            return MonitoringStateStatus.INACTIVE;
        }
    }

    getDeletedIdentity() {
        return this.deletedIdentity;
    }

    get500Error() {
        return this.has500Error;
    }

    getHasSubscription() {
        return this.hasDIPSubscription;
    }

    getProvidedIdentity() {
        return this.providedIdentity;
    }

    isIdentityStatusOk() {
        return this.providedIdentity?.status === 0;
    }

    getIdentityScanInfo() {
        return this.providedIdentity?.scan_info;
    }

    /**
     * Funciton that returns provided identity language
     * @returns Identity language
     */
    getProvidedIdentityLang(): string {
        return this.providedIdentity?.lang ?? '';
    }

    /**
     * Function that returns the creation time of the indemtity in milliseconds
     * @returns Number that represents the creation time in milliseconds of the identity creation
     */
    getProvidedIdentityCreationTime(): number {
        return this.providedIdentity?.date_created ? new Date(this.providedIdentity.date_created).getTime() : 0;
    }

    /**
     * Function that returns the type of metadata that is needed for onboarding and has the highest priority
     * @returns String that represents the type of metadata that is needed for onboarding, 'email' or 'phone'
     */
    getNextMetatdataTypeForFinishingOnboarding(): string {
        let maxPriority = Number.MAX_SAFE_INTEGER;
        let type = '';
        const requiredMetatdata = this.providedIdentity?.required_metadata ?? [];
        for (const metatdata of requiredMetatdata) {
            const priority = metatdata?.priority ?? 0;
            if (metatdata?.count && priority < maxPriority) {
                maxPriority = priority;
                type = metatdata?.type ?? '';
            }
        }
        return type;
    }

    /**
     * Function that tells you if there is at least one metadata that needs validation for onboarding.
     * Due to our flow, there will always be only one metadata that was introduced but was not verified.
     * @returns True of there is one metadata that was introduced but not validated, false otherwise
     */
    hasRequiredMetadataThatNeedsValidation(): boolean {
        return !!this.providedIdentity?.validate_required?.length;
    }

    /**
     * Function that returns the subtype of the metadata that needs validation
     * @returns String representing the subtype
     */
    getRequiredMetadataSubtypeThatNeedsValidation(): string {
        return this.providedIdentity?.validate_required?.[0]?.subtype ?? '';
    }

    isProvidedIdentityInitDone() {
        return this.providedIdentity?.init_done ?? false;
    }

    getExposureDigitalFootprint() {
        return this.exposureDigitalFootprint;
    }

    getExposureDigitalFootprintGroupped(): Array<IExposureGrouppedByService> {
        return this.exposureDigitalFootprintGroupped;
    }

    getFootprintServices(): Array<IDomainServiceDetails> {
        return this.digitalFootprintServices;
    }


    getFootprintServiceExposures(serviceId: string): Array<IExposureIssue> {
        return this.footprintServiceExposures[serviceId];
    }

    getFootprintServiceBreaches(serviceId: string): Array<IDomainServiceBreaches | IServiceBreachesImpacting> {
        return this.footprintServiceBreaches[serviceId];
    }

    getFootprintDetailedService(): IDomainServiceDetails {
        return this.footprintServiceDetails;
    }

    getExposureDashboard() {
        return this.exposureDashboard;
    }

    getExposureDashboardLid() {
        return this.exposureDashboardLid.list;
    }

    getRemediation() {
        return this.remediation;
    }

    getTotalCountDashboard() {
        return this.totalCountDashboard;
    }

    getTotalCountDashboardLid() {
        return this.exposureDashboardLid.count;
    }

    getExposureCountDigitalFootprint() {
        return this.exposureCountDigitalFootprint;
    }

    getDigitalFootprintZeroStatePerfect() {
        return this.digitalFootprintZeroState?.lte_zero_services ?? 0;
    }

    getDigitalFootprintZeroStateGood() {
        return this.digitalFootprintZeroState?.lte_two_services ?? 0;
    }

    getPrivacyScore() {
        return this.privacyScore;
    }
    getExposureCountDigitalFootprintGroupped() {
        return this.exposureCountDigitalFootprintGroupped;
    }

    /**
     * Method that return emails set for current identity
     * @returns {Array<Metadata>} list of emails
     */
    public getIdentityEmails(): Array<Metadata> {
        return this.providedIdentity.emails;
    }

    /**
     * Method that returns breaches list
     * @returns {Array<PrivacyIssue>} list of breaches
     */
    public getBreachesList(): Array<PrivacyIssue> {
        return this.breachesObj.listOfIssues;
    }

    /**
     * Method that returns breaches count
     * @returns {number} the number of breaches
     */
    public getBreachesCount(): number {
        return this.breachesObj.noOfIssues;
    }

    /**
     * Method that returns true if we listed all breaches
     * @returns {boolean} true if all breaches
     */
    public getBreachesIsListFinal(): boolean {
        return this.breachesObj.gotAllIssuesInList;
    }

    /**
     * Method that returns a single issue
     * @param {string} the id of the issue to be retriev
     * @returns {PrivacyIssue} current issue
     */
    public getIssue(issueId: string): PrivacyIssue {
        return this.issueObject[issueId];
    }

    /**
     * Method that returns impersonations object (will be replaced with commented methods)
     * @returns {IssuesObjectInfo} issues object
     */
    public getImpersonations(): IssuesObjectInfo {
        return this.impersonationsObj;
    }

    // le voi decomenta cand refactorizez impersonations
    // getImpersonationsList(): Array<PrivacyIssue> {
    //     return this.impersonationsObj.list;
    // }

    // getImpersonationsCount(): number {
    //     return this.impersonationsObj.count;
    // }

    // getImpersonationsIsListFinal(): boolean {
    //     return this.impersonationsObj.final;
    // }

    resetBreaches() {
        this.breachesObj = {
            gotAllIssuesInList: false,
            noOfIssues: 0,
            listOfIssues: [],
            zero_state: null
        };
    }

    resetImpersonations() {
        this.impersonationsObj = {
            gotAllIssuesInList: false,
            noOfIssues: 0,
            listOfIssues: [],
            zero_state: null
        };
    }

    getBreachesZeroStatePerfect() {
        return this.breachesObj?.zero_state?.lte_zero_breaches ?? 0;
    }

    getBreachesZeroStateGood() {
        return this.breachesObj?.zero_state?.lte_two_breaches ?? 0;
    }

    computeZeroStatePercentage(zeroStateValue) {
        return Math.round(100 - zeroStateValue * 100);
    }

    getEvents() {
        return this.events;
    }

    getEventsList() {
        return this.events.list;
    }

    getShowMoreEvents() {
        return !this.events.final;
    }

    getActivityExposureSummary() {
        return this.activityExposureSummary;
    }

    getActivityBreachesSummary() {
        return this.activityBreachesSummary;
    }

    getDataBreachesTotal() {
        return this.activityBreachesSummary.count?.user ?? 0;
    }

    getActivityImpersonationsSummary() {
        return this.activityImpersonationsSummary;
    }

    updateFootprintServices() {
        if (this.onlistFootprintServices$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateFootprintServices = true;
        }
    }

    setMarkToUpdateFootPrintServiceDetails(serviceId: string, value: boolean) {
        this.markToUpdateFootPrintServiceDetails[serviceId] = value;
    }

    resetInProgressFlags() {
        this.onListProvidedIdentity$.next(this.valuesService.processServiceState.DONE);
    }

    getFeatureSet(): FeatureSetEnum[] {
        return this.providedIdentity.feature_set || [];
    }

    /**
     * Method that sets a show flag for each module
     */
    computeAvailableModulesByFeatureSet() {
        this._userPrivacyModules = new Set();
        const userFeatureSet = this.providedIdentity.feature_set;
        for (let item in userFeatureSet) {
            this._userPrivacyModules.add(userFeatureSet[item]);
        }
    }

    // ! Task la connect sa adauge si acest feature pe req
    // hasActivityModule() {
    //     return this._userPrivacyModules.has(FeatureSetEnum.ACTIVITY);
    // }

    hasFootprintModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.EXPOSURE);
    }

    hasBreachesModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.BREACH);
    }

    hasImpersonationsModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.IMPERSONATION);
    }

    hasBrokersModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.DATABROKERS);
    }

    hasEducationModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.EDUCATION);
    }

    // ! Task la connect sa adauge si acest feature pe req
    // hasHistoryModule() {
    //     return this._userPrivacyModules.has(FeatureSetEnum.HISTORY);
    // }

    // ! Task la connect sa adauge si acest feature pe req
    // hasMonitorModule() {
    //     return this._userPrivacyModules.has(FeatureSetEnum.MONITOR);
    // }

    /**
     * Method that gets all available modules
     */
    getActiveModules() {
        return this._userPrivacyModules;
    }

    activateIDTTimeout() {
        localStorage.setItem(this.privacyValuesService.idtTimeoutActivatedItem, 'true');
    }

    getIDTTimeoutState() {
        return localStorage.getItem(this.privacyValuesService.idtTimeoutActivatedItem);
    }
}

