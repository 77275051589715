// External
import { Component, Input, HostListener, OnInit} from '@angular/core';

// Internal
import { TooltipService } from '../../../services/global/tooltip/tooltip.service';

@Component({
  selector: 'ui-tooltip',
  template: `<div class="tooltip {{position}}" #tooltip
	  [style.top.px]="triggerCoords.top + offsetTop"
      [style.left.px]="triggerCoords.left + offsetLeft"
      [style.width.px]="width">
	  <div class="tooltip-content {{color}}">{{title}}</div></div>`
})
export class UiTooltipComponent implements OnInit {

    @Input() ref: any;
    @Input() title: string;
    @Input() position: string;
    @Input() color: string;
    @Input() width: number;

    // positioning
    triggerCoords: any;
    offsetLeft = 0;
    offsetTop = 0;

    constructor(
        readonly tooltipService: TooltipService) { }

    ngOnInit() {
        this.positionTooltip(this.ref.nativeElement);
    }

    // @HostListener('window:resize') onWindowResize(): void {
    //     // update position based on `ref`
    // }


    @HostListener('window: mousewheel', ['$event']) onScroll(event) {
        this.tooltipService.removeTooltip();
    }

    // svgElements doesn't have offsetWidth/height
    positionTooltip(triggerElement: { offsetWidth: number; offsetHeight: number; getBoundingClientRect: () => any; }) {
        if(triggerElement.offsetWidth) {
            if (this.position === 'top') {
                this.offsetLeft = triggerElement.offsetWidth/2;
            }

            if (this.position === 'right') {
                this.offsetLeft = triggerElement.offsetWidth;
                this.offsetTop = triggerElement.offsetHeight/2;
            }

            if (this.position === 'bottom') {
                this.offsetLeft = triggerElement.offsetWidth/2;
                this.offsetTop = triggerElement.offsetHeight;
            }

            if (this.position === 'left') {
                this.offsetTop = triggerElement.offsetHeight/2;
            }
        }

        this.triggerCoords = triggerElement.getBoundingClientRect();
    }
}