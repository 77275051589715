//#region Generic Const, Types & Enums
const SECONDS_IN_DAY       = 86400;
const SECONDS_HALF_AN_HOUR = 1800;
const DAYS_IN_A_WEEK       = 7;

export const API_PROCESSING_ERROR_CODE = 39001;
export const EXCEPTION_UNKNOWN = {
    id: 'Unknown',
    name: 'parental.contentfiltering.categories.names.unknown2'
};

export const EXCEPTION_NA = {
    id: 'N/A',
    name: 'parental.contentfiltering.categories.names.na'
};

export type ScreenTimeGroupByType = 'day' | 'hour' | 'category' | 'topic';

export enum NccUpdateActionType {
    set   = 'set',
    clear = 'clear'
}

export enum NccDeviceAtRiskType {
    parental = 'parental',
    location = 'location',
    none = 'none'
}

enum daysOfWeek {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WENDSDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY
}

export enum NccExceptionType {
    websites   = 'websites',
    topics     = 'topics',
    categories = 'categories',
    block_all  = 'block_all_internet'
}

export enum NccReplacementsType {
    youtube = 'youtube',
    google  = 'google'
}

export enum ScreenTimeGroupBy {
    DAY      = 'day',
    HOUR     = 'hour',
    CATEGORY = 'category',
    TOPIC    = 'topic'
}

export enum parentalNccUsageOrderby {
    aplhabeticallyAsc  = 'aplhabeticallyAsc',
    alphabeticallyDesc = 'alphabeticallyDesc',
    timeAsc            = 'timeAsc',
    timeDesc           = 'timeDesc'
}

export enum parentalNccDeviceSoftware {
    logout      = 'logout',
    uninstalled = 'product_uninstalled',
    empty       = '-'
}

export enum ParentalFilterType {
    teen    = 'teen',
    toddler = 'toddler',
    kid     = 'kid'
}

export enum ParentalMonths {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
}

export const ParentalDaysInMonth = {
    [ParentalMonths.January]: 31,
    [ParentalMonths.February]: 28,
    [ParentalMonths.March]: 31,
    [ParentalMonths.April]: 30,
    [ParentalMonths.May]: 31,
    [ParentalMonths.June]: 30,
    [ParentalMonths.July]: 31,
    [ParentalMonths.August]: 31,
    [ParentalMonths.September]: 30,
    [ParentalMonths.October]: 31,
    [ParentalMonths.November]: 30,
    [ParentalMonths.December]: 31,
}
//#endregion

//#region Interfaces
/**
 *
 * Type use for NCC DTL limit for a day
 *
 * @field {NccUpdateActionType} action - can be 'set' or 'clear'
 * @field {daysOfWeek} week_day: 0-6 starting with Sunday
 * @field {number} limit? - time in seconds (only on set action)
 */
export interface IUpdateDtl {
    action:   NccUpdateActionType,
    week_day: daysOfWeek,
    limit?:   number
}

/**
 *
 * Type use for NCC Replacements (Google and Youtube) update
 *
 * @field {NccUpdateActionType} action - can be 'set' or 'clear'
 * @field {NccReplacementsType} replacement - google or youtube
 * @field {boolean} enabled?
 */
export interface IUpdateReplacements{
    action:   NccUpdateActionType,
    replacement: NccReplacementsType
    enabled?: boolean
}

/**
 *
 * Type use for NCC Exception & Routine Exceptions (Categories, Websites and Topics) update
 *
 * @field {NccUpdateActionType} action - can be 'set' or 'clear'
 * @field {NccExceptionType} type - categories, websites, topics
 * @field {string} value -  categories: name, topic: id, website: url
 * @field {boolean} allow? - used only on set action
 */
export interface IUpdateException {
    action: NccUpdateActionType,
    type:   NccExceptionType,
    value:  string,
    allow?: boolean
}

/**
 *
 * Type use for NCC Routines Schedule update
 *
 * @field {NccUpdateActionType} action - can be 'set' or 'clear'
 * @field {daysOfWeek} week_day - 0-6 starting with Sunday
 * @field {number} start_time? - start time in seconds (only on set action)
 * @field {number} end_time? - end time in seconds (only on set action)
 */
export interface IUpdateRoutine {
    action:        NccUpdateActionType,
    week_day:      daysOfWeek,
    start_time?:   number,
    end_time?:     number,
}

export interface ILocation {
    lat: number;
    lng: number;
    location: string;
    time: number;
    _id: string;
}

export interface IParentalNccOptions {
    method: string,
    params: {}
}
//#endregion

//#region Parental NCC Api fields
export enum parentalNccApiSettingsFields {
    exceptions     = "exceptions",
    dtl            = "dtl",
    routines       = "routines",
    replacements   = "replacements",
    infoCategories = "info_categories",
    infoTopics     = "info_topics"
}

export enum parentalNccApiProfileFields {
    profileStats    = 'profile_stats',
    timeSpentToday  = 'time_spent_today',
    needMoreTime    = 'need_more_time',
    deviceStatus    = 'device_status',
    deviceLocation  = 'device_location'
}
//#endregion

//#region Parental NCC Api methods
enum parentalNccApiMethods {
    //Profile Stats
    getProfileStats          = 'get_profile_stats',
    getTimeSpentToday        = 'get_time_spent_today',
    //Rewards
    getMoreTimeRequests      = 'get_more_time_requests',
    grantMoreTime            = 'grant_more_time',
    //Devices & Location
    getProfileLocations      = 'get_profile_locations',
    getDeviceLocation        = 'get_device_location',
    getDevicesStatus         = 'get_devices_status',
    //Settings
    getSettings              = 'get_settings',
    //DTL
    getDtl                   = 'get_dtl',
    udateDtl                 = 'update_dtl',
    setDtlStatus             = 'set_dtl_status',
    //Replacements
    getReplacements          = 'get_replacements',
    updateReplacements       = 'update_replacements',
    //Exceptions
    getExceptions            = 'get_exceptions',
    updateExceptions         = 'update_exceptions',
    removeExceptions         = 'remove_exceptions',
    resetExceptions          = 'reset_exceptions',
    setBlockAll              = 'set_block_all_internet',
    //Routines
    getRoutines              = 'get_routines',
    setRoutinesStatus        = 'set_routine_status',
    updateRoutinesSchedule   = 'update_routine_schedule',
    updateRoutinesExceptions = 'update_routine_exceptions',
    setRoutinesBlockAll      = 'set_routine_block_all_internet',
    resetRoutine             = 'reset_routine',
    //Generic Info
    getGenericInfo           = 'get_generic_info',
    getHostInfo              = 'get_host_info'
}
//#endregion

//#region NCC Api Params Options&Process

export class ParentalNccMgmtOptions {
    //#region ProfileStats
    static getProfileStats(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getProfileStats,
            params: {
                profile_id: profileId
            }
        };
    }

    static getTimeSpentToday(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getTimeSpentToday,
            params: {
                profile_id: profileId
            }
        };
    }
    //#endregion
    //#region Rewards
    static getMoreTimeRequests(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getMoreTimeRequests,
            params: {
                profile_id: profileId
            }
        };
    }

    static grantMoreTime(profileId: string, grantedTime : number): IParentalNccOptions {
        if ((grantedTime < 0 || grantedTime > SECONDS_IN_DAY) && grantedTime % SECONDS_HALF_AN_HOUR === 0) {
            throw new Error(`grantedTime does not fit the range 0 - ${SECONDS_IN_DAY} or it's not multiple of ${SECONDS_HALF_AN_HOUR}`)
        }
        return {
            method: parentalNccApiMethods.grantMoreTime,
            params: {
                profile_id: profileId,
                granted_time: grantedTime
            }
        };
    }
    //#endregion
    //#region Devices & Location
    static getProfileLocations(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getProfileLocations,
            params: {
                profile_id: profileId
            }
        };
    }

    static getDeviceLocation(profileId: string, deviceId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getDeviceLocation,
            params: {
                profile_id: profileId,
                device_id: deviceId
            }
        };
    }

    static getDevicesStatus(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getDevicesStatus,
            params: {
                profile_id: profileId
            }
        };
    }
    //#endregion
    //#region Settings (One or all Fields)
    static getSettings(profileId: string, settings: parentalNccApiSettingsFields[]): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getSettings,
            params: {
                profile_id: profileId,
                settings
            }
        };
    }
    //#endregion
    //#region DTL
    static getDtl(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getDtl,
            params: {
                profile_id: profileId
            }
        };
    }
    static updateDtl(profileId: string, updates: IUpdateDtl[]): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.udateDtl,
            params: {
                profile_id: profileId,
                updates
            }
        };
    }

    static removeLimitDtl(profileId: string, day: number): IParentalNccOptions {
        const update: IUpdateDtl = {
            action: NccUpdateActionType.set,
            week_day: day,
            limit: SECONDS_IN_DAY
        };

        return {
            method: parentalNccApiMethods.udateDtl,
            params: {
                profile_id: profileId,
                updates : [update]
            }
        };
    }

    static recomandedAllDaysDtl(profileId: string): IParentalNccOptions {

        const updates: IUpdateDtl[] = [];
        for (let i = 0; i < DAYS_IN_A_WEEK; i++) {
            updates.push({
                action: NccUpdateActionType.clear,
                week_day: i,
            });
        }
        return {
            method: parentalNccApiMethods.udateDtl,
            params: {
                profile_id: profileId,
                updates
            }
        };
    }

    static setDtlStatus(profileId: string, enable: boolean): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.setDtlStatus,
            params: {
                profile_id: profileId,
                enable
            }
        };
    }
    //#endregion
    //#region Replacements (Youtube | Google)
    static getReplacements(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getReplacements,
            params: {
                profile_id: profileId
            }
        };
    }

    static updateReplacements(profileId: string, updates: IUpdateReplacements[] ): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.updateReplacements,
            params: {
                profile_id: profileId,
                updates
            }
        };
    }
    //#endregion
    //#region Exceptions (Categories, Topics, Websites and BlockAll)
    static getExceptions(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getExceptions,
            params: {
                profile_id: profileId
            }
        };
    }

    static updateExceptions(profileId: string, updates: IUpdateException[]): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.updateExceptions,
            params: {
                profile_id: profileId,
                updates
            }
        };
    }

    static resetExceptions(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.resetExceptions,
            params: {
                profile_id: profileId,
                types: [NccExceptionType.categories, NccExceptionType.topics, NccExceptionType.websites]
            }
        };
    }

    static setExceptionsblockAll(profileId: string, enabled: boolean): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.setBlockAll,
            params: {
                profile_id: profileId,
                block_all_internet: enabled
            }
        };
    }
    //#endregion
    //#region Routines
    static getRoutines(profileId: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getRoutines,
            params: {
                profile_id: profileId
            }
        };
    }

    static setRoutinesStatus(profileId: string, routine: string, enable: boolean): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.setRoutinesStatus,
            params: {
                profile_id: profileId,
                routine,
                enable
            }
        };
    }

    static updateRoutinesSchedule(profileId: string, routine: string, updates: IUpdateRoutine[]): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.updateRoutinesSchedule,
            params: {
                profile_id: profileId,
                routine,
                updates
            }
        };
    }

    static updateRoutinesException(profileId: string, routine: string, updates: IUpdateException[]): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.updateRoutinesExceptions,
            params: {
                profile_id: profileId,
                routine,
                updates
            }
        };
    }

    static setRoutinesBlockAll(profileId: string, routine: string, blockAll: boolean): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.setRoutinesBlockAll,
            params: {
                profile_id: profileId,
                routine,
                block_all_internet: blockAll
            }
        };
    }

    static resetRoutineExceptions(profileId: string, routine: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.resetRoutine,
            params: {
                profile_id: profileId,
                routine
            }
        };
    }
    //#endregion
    //#region Generic Info (Categories, Topics and Hosts)
    static getGenericInfo(profileId: string, fields: parentalNccApiSettingsFields[]): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getGenericInfo,
            params: {
                profile_id: profileId,
                fields
            }
        };
    }

    static getHostInfo(host: string): IParentalNccOptions {
        return {
            method: parentalNccApiMethods.getHostInfo,
            params: {
                host
            }
        };
    }
    //#endregion
}
//#endregion
