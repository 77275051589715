//  External
import { Injectable } from '@angular/core';

//  Internal
import { ConfigService } from '../config/config.service';
import { MetadataType } from '../models/privacy/Identity.model';
import { ValuesService } from './values.service';
import { GenderType } from '../models/privacy/PrivacyExposure.model';
import { ModalName } from '../components/ui/ui-modal-routeless/modal.routeless.service';
import { PrivacyIssueType, ActionType, IssueActionStatus, BreachType } from '../models/privacy/PrivacyIssue.model';
import { HeatMapScale } from '../components/ui/ui-heat-map/ui-heat-map.model';
import { RemediationActionType, RemediationActionDetails } from '../models/privacy/Remediation.model';
import { PrivacyEventsTypes, PrivacyEventsSubtypes, EventDescriptors, EventConditions, EventTypes, PrivacyDescTypes } from '../services/global/events/events.model';

export const MEDIUM_SCORE_LOWER_LIMIT = 34;
export const MEDIUM_SCORE_UPPER_LIMIT = 66;
export const BREACHES_PRIVACY_SCORE_MEDIUM = 3.33;
export const BREACHES_PRIVACY_SCORE_HIGH = 6.66;
export const BREACHES_PRIVACY_CHANGES_ONE = 1;
export const BREACH_EXPOSABLE_INFO = new Set(['email', 'domain', 'password_info']);

export enum AboutModalSections {
    FOOTPRINT = 'footprint',
    BREACHES = 'breaches',
    IMPERSONATIONS = 'impersonations',
    EDUCATION = 'education',
    ACTIVITY = 'activity',
    IDENTITY_MONITOR = 'identityMonitor'
};

@Injectable({
    providedIn: 'root'
})

export class PrivacyValuesService {

    constructor(
        readonly configService: ConfigService,
        readonly valuesService: ValuesService
    ) { }

    securedProtocol = 'https';

    error_codes = {
        'expired_subscription': 40037,
        'no_identity_found': 40012,
        'no_service_found': 40052
    };

    breachesStatusScan = {
        done: 'done'
    };

    NO_EXPOSURES_PER_LOAD = 15;
    NO_EXPOSURES_BUBBLE_VIEW = 10;
    LOADER_STATE = {
        OFF: "off",
        ON: "on"
    };
    REMEDIATION = "remediation";
    THIRTY_DAYS_IN_MILLISECONDS = 2592000000; // 30 * 24 * 3600 * 1000

    privacyBreachesIndentities = {
        million: 'dashboard.privacy.breaches.million.indentities',
        billion: 'dashboard.privacy.breaches.billion.indentities'
    };

    controlYourPrivacyQueryParam = "controlYourPrivacy";
    clickEventsOmniture = {
        show_more:                  'loadmore:exposures:{{exposure_type}}',
        network_view:               'network-view',
        table_view:                 'table-view',
        undo:                       'undo:{{info_type}}',
        check:                      'Check:{{action_type}}',
        fixed:                      'Fixed',
        set_relevancy:              'SetRelevancy:{{relevancy}}',
        view:                       'View:{{action_type}}',
        feedback:                   'feedback:{{type}}:{{subtype}}',
        enable_email:               'enableEmails:{{type}}',
        learn_more:                 'LearnMore',
        renew_subscription_region:  'RenewSubscriptionRegion',
        renew_subscription:         'RenewSubscription',
        check_now:                  'CheckNow',
        different_metadata:         'Different{{metadata}}Continue',
        manage_remediation:         'Remediation:{{service_name}}:{{remediation_type}}',
        learn_more_leaks:           'CredentialsLeaked:Password:LearnMore',
        learn_more_breaches: {
            0:                      'CredentialsLeaked:Breaches:LearnMore:0',
            1:                      'CredentialsLeaked:Breaches:LearnMore:1',
            2:                      'CredentialsLeaked:Breaches:LearnMore:2',
            3:                      'CredentialsLeaked:Breaches:LearnMore:3',
            4:                      'CredentialsLeaked:Breaches:LearnMore:4'
        },
        open_email_client:          'Remediation:{{service_name}}:OpenEmailClient',
        open_education_databrokers: 'DataBrokers:OpenEducation',
        sent:                       'sent',
    };

    extraSectionsOmniture = {
        monitoring_system:           'monitoringsystem',
        onboarding_has_error:        'OnBoardingHasError',
        completed:                   'completed',
        subscription_has_error:      'CheckSubscriptionHasError',
        verify_exposure:             'verify{{exposure_type}}',
        enter_exposure:              'enter{{exposure_type}}',
        welcome:                     'welcome',
        how_it_works:                'howitworks',
        feedback_open:               'feedback_open',
        feedback_sent:               'feedback_sent',
        empty_region:                'EmptyRegion',
        empty:                       'empty',
        service_remediation:         '{{service_name}}:{{remediation_type}}',
        feedback_service_remediation:'feedback:{{service_name}}:{{remediation_type}}',
        email_template:              '{{service_name}}:emailTemplate'
    };

    privacyScore = {
        highColors: ['#c4081f', '#7f0211'],
        mediumColors: ['#7f3702', '#e66306'],
        lowColors: ['#0a9e51', '#3afb95'],
        total: 100,
        circles: [
            {
                color: '#212329',
                xAxys: 0,
                yAxys: 0,
                radius: 1.5,
                tooltip: 'privacy.activity.identity.protection.score.average',
                text: '',
                average: true,
                progress: '0.5;0.5'
            },
            {
                color: '#53565D',
                xAxys: 0,
                yAxys: 0,
                radius: 1.5,
                tooltip: 'privacy.activity.identity.protection.score.maximum',
                text: '',
                average: false,
                progress: '1;1'
            }
        ]
    };

    breachesRiskLevels = {
        LOW: {
            class: 'neutral',
            text: 'privacy.breach.risk.low'
        },
        MEDIUM: {
            class: 'warning',
            text: 'privacy.breach.risk.medium'
        },
        HIGH: {
            class: 'danger',
            text: 'privacy.breach.risk.high'
        },
    };

    breachesRiskCategoriesText = {
        translationsPrefix: 'privacy.breaches.risks.modal.',
        sensitiveData: 'privacy.breach.dataclass.sensitive_data'
    };

    riskMapXScale: HeatMapScale = {
        visible: true,
        max: 10,
        title: 'privacy.risk.map.impact.title',
        tooltip: {
            title: 'privacy.risk.map.impact.title',
            text: 'privacy.risk.map.impact.description'
        }
    };

    riskMapYScale: HeatMapScale = {
        visible: true,
        max: 10,
        title: 'privacy.risk.map.likelihood.title',
        tooltip: {
            title: 'privacy.risk.map.likelihood.title',
            text: 'privacy.risk.map.likelihood.description'
        }
    };

    footprintRemediationDefaultActions: RemediationActionDetails[] = [
        {
            type: RemediationActionType.REMOVE_PERSONAL_INFO,
            text: 'privacy.remediation.action.remove_personal_info',
            url: ''
        }
    ];

    privacyFootprintCategories = {
        ads: 'privacy.footprint.category.advertising',
        advice: 'privacy.footprint.category.advice',
        alcohol: 'privacy.footprint.category.alcohol',
        astrology: 'privacy.footprint.category.astrology',
        auto: 'privacy.footprint.category.auto',
        blogs: 'privacy.footprint.category.blogs',
        business: 'privacy.footprint.category.business',
        computersandsoftware: 'privacy.footprint.category.computers',
        drugs: 'privacy.footprint.category.drugs',
        education: 'privacy.footprint.category.education',
        entertainment: 'privacy.footprint.category.entertainment',
        filesharing: 'privacy.footprint.category.filesharing',
        financial: 'privacy.footprint.category.financial',
        food: 'privacy.footprint.category.food',
        gambling: 'privacy.footprint.category.gambling',
        games: 'privacy.footprint.category.games',
        government: 'privacy.footprint.category.government',
        hacking: 'privacy.footprint.category.hacking',
        hate: 'privacy.footprint.category.hate',
        health: 'privacy.footprint.category.health',
        hobbies: 'privacy.footprint.category.hobbies',
        hosting: 'privacy.footprint.category.hosting',
        illegalactivities: 'privacy.footprint.category.illegalactivities',
        illegal: 'privacy.footprint.category.illegal',
        im: 'privacy.footprint.category.im',
        jobsearch: 'privacy.footprint.category.jobsearch',
        kids: 'privacy.footprint.category.kids',
        lifestyle: 'privacy.footprint.category.lifestyle',
        lingerie: 'privacy.footprint.category.lingerie',
        maturecontent: 'privacy.footprint.category.maturecontent',
        marijuana: 'privacy.footprint.category.marijuana',
        misc: 'privacy.footprint.category.misc',
        narcotics: 'privacy.footprint.category.narcotics',
        narcoticsgeneral: 'privacy.footprint.category.narcoticsgeneral',
        news: 'privacy.footprint.category.news',
        nudity: 'privacy.footprint.category.nudity',
        occult: 'privacy.footprint.category.occult',
        onlinedating: 'privacy.footprint.category.dating',
        onlinepay: 'privacy.footprint.category.onlinepay',
        onlinephotos: 'privacy.footprint.category.onlinephotos',
        onlineshop: 'privacy.footprint.category.onlineshop',
        pets: 'privacy.footprint.category.pets',
        pharmacy: 'privacy.footprint.category.pharmacy',
        porn: 'privacy.footprint.category.porn',
        piracy: 'privacy.footprint.category.piracy',
        portals: 'privacy.footprint.category.portals',
        radiomusic: 'privacy.footprint.category.radiomusic',
        realestate: 'privacy.footprint.category.realestate',
        religion: 'privacy.footprint.category.religion',
        searchengines: 'privacy.footprint.category.searchengines',
        sextoys: 'privacy.footprint.category.sextoys',
        sexualcontent: 'privacy.footprint.category.sexualcontent',
        sexualeducation: 'privacy.footprint.category.sexualeducation',
        socialnetworks: 'privacy.footprint.category.socialnetworks',
        society: 'privacy.footprint.category.society',
        sports: 'privacy.footprint.category.sports',
        suicide: 'privacy.footprint.category.suicide',
        tabloids: 'privacy.footprint.category.tabloids',
        tobacco: 'privacy.footprint.category.tobacco',
        timewasters: 'privacy.footprint.category.timewasters',
        travel: 'privacy.footprint.category.travel',
        videos: 'privacy.footprint.category.videos',
        violentcartoons: 'privacy.footprint.category.violentcartoons',
        weapons: 'privacy.footprint.category.weapons',
        webmail: 'privacy.footprint.category.webmail',
        webproxy: 'privacy.footprint.category.webproxy'
    }

    personalDataCategories = {
        preferences: 'preferences',
        advertisingProfile: 'advertisingProfile',
        demographicData: 'demographicData',
        knowledge: 'knowledge',
        search: 'search',
        authentication: 'authentication',
        privateMessages: 'privateMessages',
        financial: 'financial',
        purchases: 'purchases',
        shipping: 'shipping',
        contentGenerated: 'contentGenerated',
        comments: 'comments',
        cookies: 'cookies',
        identificationData: 'identificationData',
        subscription: 'subscription',
        documents: 'documents',
        transactions: 'transactions',
        topUp: 'topUp',
        medical: 'medical',
        jobEducation: 'jobEducation',
        songs: 'songs',
        social: 'social',
        booking: 'booking',
        videos: 'videos',
        emails: 'emails',
        contact: 'contact',
        ip: 'ip',
        parked: 'parked',
    };

    personalDataCategoriesTranslations = {
        preferences: 'privacy.footprint.personal.data.category.preferences',
        advertisingProfile: 'privacy.footprint.personal.data.category.advertising',
        demographicData: 'privacy.footprint.personal.data.category.demographic',
        knowledge: 'privacy.footprint.personal.data.category.knowledge',
        search: 'privacy.footprint.personal.data.category.search',
        authentication: 'privacy.footprint.personal.data.category.authentication',
        privateMessages: 'privacy.footprint.personal.data.category.private',
        financial: 'privacy.footprint.personal.data.category.financial',
        purchases: 'privacy.footprint.personal.data.category.purchases',
        shipping: 'privacy.footprint.personal.data.category.shipping',
        contentGenerated: 'privacy.footprint.personal.data.category.content',
        comments: 'privacy.footprint.personal.data.category.comments',
        cookies: 'privacy.footprint.personal.data.category.cookies',
        identificationData: 'privacy.footprint.personal.data.category.identification',
        subscription: 'privacy.footprint.personal.data.category.subscription',
        documents: 'privacy.footprint.personal.data.category.documents',
        transactions: 'privacy.footprint.personal.data.category.transactions',
        topUp: 'privacy.footprint.personal.data.category.topup',
        medical: 'privacy.footprint.personal.data.category.medical',
        jobEducation: 'privacy.footprint.personal.data.category.job',
        songs: 'privacy.footprint.personal.data.category.songs',
        social: 'privacy.footprint.personal.data.category.social',
        booking: 'privacy.footprint.personal.data.category.booking',
        videos: 'privacy.footprint.personal.data.category.videos',
        emails: 'privacy.footprint.personal.data.category.emails',
        contact: 'privacy.footprint.personal.data.category.contact',
        ip: 'privacy.footprint.personal.data.category.ip',
    };

    personalDataCategoriesIcons = {
        preferences: 'personal_interests',
        advertisingProfile: 'offers',
        demographicData: 'dates_of_birth',
        knowledge: 'education_level',
        search: 'magnifying-glass-search',
        authentication: 'names',
        privateMessages: 'private_messages',
        financial: 'financial_data',
        purchases: 'purchases',
        shipping: 'shipping',
        contentGenerated: 'content',
        comments: 'advice',
        cookies: 'dates_of_birth',
        identificationData: 'user_data',
        subscription: 'payment_histories',
        documents: 'privacy.footprint.personal.data.category.documents',
        transactions: 'privacy.footprint.personal.data.category.transactions',
        topUp: 'privacy.footprint.personal.data.category.topup',
        medical: 'health',
        jobEducation: 'employers',
        songs: 'song',
        social: 'social_profiles',
        booking: 'booking',
        videos: 'video',
        emails: 'privacy.footprint.personal.data.category.emails',
        contact: 'bios',
        ip: 'privacy.footprint.personal.data.category.ip',
    };

    riskMapCircleValues = {
        frequent: {
            low: '#EA0220',
            moderate: '#EA0220',
            high: '#B30118',
        },
        common: {
            low: '#2C8B2C',
            moderate: '#F46A00',
            high: '#EA0220',
        },
        rare: {
            low: '#0C4C0C',
            moderate: '#2C8B2C',
            high: '#EA0220',
        },
        maxScore: 10,
        noCells: 6
    };

    riskMapDashboardLevels = {
        frequent: {
            low: {
                description: 'privacy.activity.identity.protection.risk.map.dashboard.risk.low.frequent.description',
                action: 'privacy.activity.identity.protection.risk.map.dashboard.risk.low.frequent.action',
            },
            moderate: {
                description: 'privacy.activity.identity.protection.risk.map.dashboard.risk.moderate.frequent.description',
                action: 'privacy.activity.identity.protection.risk.map.dashboard.risk.moderate.frequent.action'
            },
            high: {
                description: 'privacy.activity.identity.protection.risk.map.dashboard.risk.high.frequent.description',
                action: 'privacy.activity.identity.protection.risk.map.dashboard.risk.high.frequent.action'
            }
        },
        common: {
            low: {
                description: 'privacy.activity.identity.protection.risk.map.dashboard.risk.low.common.description',
                action: 'privacy.activity.identity.protection.risk.map.dashboard.risk.low.common.action',
            },
            moderate: {
                description: 'privacy.activity.identity.protection.risk.map.dashboard.risk.moderate.common.description',
                action: 'privacy.activity.identity.protection.risk.map.dashboard.risk.moderate.common.action'
            },
            high: {
                description: 'privacy.activity.identity.protection.risk.map.dashboard.risk.high.common.description',
                action: 'privacy.activity.identity.protection.risk.map.dashboard.risk.high.common.action'
            }
        },
        rare: {
            low: {
                description: 'privacy.activity.identity.protection.risk.map.dashboard.risk.low.rare.description',
                action: 'privacy.activity.identity.protection.risk.map.dashboard.risk.low.rare.action',
            },
            moderate: {
                description: 'privacy.activity.identity.protection.risk.map.dashboard.risk.moderate.rare.description',
                action: 'privacy.activity.identity.protection.risk.map.dashboard.risk.moderate.rare.action'
            },
            high: {
                description: 'privacy.activity.identity.protection.risk.map.dashboard.risk.high.rare.description',
                action: 'privacy.activity.identity.protection.risk.map.dashboard.risk.high.rare.action'
            }
        },
        title: 'privacy.activity.identity.protection.risk.map.modal.overview.title'
    };

    riskMapOverviewLevels = {
        frequent: {
            low: {
                description: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.low.frequent.description',
                action: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.low.frequent.action'
            },
            moderate: {
                description: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.moderate.frequent.description',
                action: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.moderate.frequent.action'
            },
            high: {
                description: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.high.frequent.description',
                action: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.high.frequent.action'
            }
        },
        common: {
            low: {
                description: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.low.common.description',
                action: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.low.common.action'
            },
            moderate: {
                description: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.moderate.common.description',
                action: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.moderate.common.action'
            },
            high: {
                description: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.high.common.description',
                action: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.high.common.action'
            }
        },
        rare: {
            low: {
                description: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.low.rare.description',
                action: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.low.rare.action'
            },
            moderate: {
                description: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.moderate.rare.description',
                action: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.moderate.rare.action'
            },
            high: {
                description: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.high.rare.description',
                action: 'privacy.activity.identity.protection.risk.map.modal.overview.risk.high.rare.action'
            }
        },
        title: 'privacy.activity.identity.protection.risk.map.modal.overview.title'
    };

    riskMapExpertLevels = {
        email: {
            icon: 'email_addresses',
            text: 'privacy.activity.identity.protection.risk.map.modal.expert.risk.email',
        },
        email_name: {
            icon: 'user_email',
            text: 'privacy.activity.identity.protection.risk.map.modal.expert.risk.email.name',
        },
        ip_address: {
            icon: 'ip_addresses',
            text: 'privacy.activity.identity.protection.risk.map.modal.expert.risk.ip',
        },
        phone_number: {
            icon: 'phone_numbers',
            text: 'privacy.activity.identity.protection.risk.map.modal.expert.risk.phone',
        },
        phone_number_and_name: {
            icon: 'user_phone',
            text: 'privacy.activity.identity.protection.risk.map.modal.expert.risk.phone.name',
        },
        email_and_decoded_password: {
            icon: 'password_decoded',
            text: 'privacy.activity.identity.protection.risk.map.modal.expert.risk.email.decoded',
        },
        email_and_encoded_password: {
            icon: 'password_encoded',
            text: 'privacy.activity.identity.protection.risk.map.modal.expert.risk.email.encoded',
        },
        email_and_plaintext_password: {
            icon: 'password_plain',
            text: 'privacy.activity.identity.protection.risk.map.modal.expert.risk.email.password',
        },
        email_password_2fa: {
            icon: 'email_2fa_disabled',
            text: 'privacy.activity.identity.protection.risk.map.modal.expert.risk.email.password.2fa.disabled',
        },
        likelihoodText: 'privacy.activity.identity.protection.risk.map.modal.expert.likelihood',
        impactText: 'privacy.activity.identity.protection.risk.map.modal.expert.impact',
        learnMoreText: 'privacy.activity.identity.protection.risk.map.modal.expert.learn.more',
        averageText: 'privacy.activity.identity.protection.risk.map.modal.expert.average',
        iconsType: 'privacy',
        iconsClass: 'mr-1 color-shade',
        iconsExtension: '_alt.svg',
        iconsPath: '/ux/icons/privacy/riskMap/',
        iconsSize: 24
    };

    digitalFootprintValues = {
        minCategories: 1,
        maxCategories: 2,
        defaultIssuesDisplayed: 5,
        remediationMethodText: 'privacy.footprint.remediation.settings.link',
        remediationActionText: 'privacy.remediation.action',
        mobileCategory: 'Mobile Directory',
        directionTop: 'top',
        directionDown: 'down'
    };

    idtOmniture = {
        timeoutActivated: 'idt_content_hidden',
        reloginClick: 'idt_continue_flow'
    };

    idtTimeoutActivatedItem = 'idtTimeoutActivated';

    country_prefixes = [
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AU-128.png",
            "iso2": "AU",
            "name": "Australia",
            "prefix": "61"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CA-128.png",
            "iso2": "CA",
            "name": "Canada",
            "prefix": "1"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/FR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/FR-128.png",
            "iso2": "FR",
            "name": "France",
            "prefix": "33"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/DE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/DE-128.png",
            "iso2": "DE",
            "name": "Germany",
            "prefix": "49"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IL-128.png",
            "iso2": "IL",
            "name": "Israel",
            "prefix": "972"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MX-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MX-128.png",
            "iso2": "MX",
            "name": "Mexico",
            "prefix": "52"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/RO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/RO-128.png",
            "iso2": "RO",
            "name": "Romania",
            "prefix": "40"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GB-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GB-128.png",
            "iso2": "GB",
            "name": "United Kingdom",
            "prefix": "44"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/US-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/US-128.png",
            "iso2": "US",
            "name": "United States",
            "prefix": "1"
        }
    ];

    country_prefixes_all = [
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AF-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AF-128.png",
            "iso2": "AF",
            "name": "Afghanistan",
            "prefix": "93"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AX-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AX-128.png",
            "iso2": "AX",
            "name": "Aland Islands",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AL-128.png",
            "iso2": "AL",
            "name": "Albania",
            "prefix": "355"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/DZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/DZ-128.png",
            "iso2": "DZ",
            "name": "Algeria",
            "prefix": "213"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AS-128.png",
            "iso2": "AS",
            "name": "American Samoa",
            "prefix": "1684"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AD-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AD-128.png",
            "iso2": "AD",
            "name": "Andorra",
            "prefix": "376"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AO-128.png",
            "iso2": "AO",
            "name": "Angola",
            "prefix": "244"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AI-128.png",
            "iso2": "AI",
            "name": "Anguilla",
            "prefix": "1264"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AQ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AQ-128.png",
            "iso2": "AQ",
            "name": "Antarctica",
            "prefix": "672"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AG-128.png",
            "iso2": "AG",
            "name": "Antigua and Barbuda",
            "prefix": "1268"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AR-128.png",
            "iso2": "AR",
            "name": "Argentina",
            "prefix": "54"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AM-128.png",
            "iso2": "AM",
            "name": "Armenia",
            "prefix": "374"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AW-128.png",
            "iso2": "AW",
            "name": "Aruba",
            "prefix": "297"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AU-128.png",
            "iso2": "AU",
            "name": "Australia",
            "prefix": "61"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AT-128.png",
            "iso2": "AT",
            "name": "Austria",
            "prefix": "43"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AZ-128.png",
            "iso2": "AZ",
            "name": "Azerbaijan",
            "prefix": "994"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BS-128.png",
            "iso2": "BS",
            "name": "Bahamas",
            "prefix": "1242"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BH-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BH-128.png",
            "iso2": "BH",
            "name": "Bahrain",
            "prefix": "973"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BD-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BD-128.png",
            "iso2": "BD",
            "name": "Bangladesh",
            "prefix": "880"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BB-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BB-128.png",
            "iso2": "BB",
            "name": "Barbados",
            "prefix": "1246"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BY-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BY-128.png",
            "iso2": "BY",
            "name": "Belarus",
            "prefix": "375"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BE-128.png",
            "iso2": "BE",
            "name": "Belgium",
            "prefix": "32"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BZ-128.png",
            "iso2": "BZ",
            "name": "Belize",
            "prefix": "501"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BJ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BJ-128.png",
            "iso2": "BJ",
            "name": "Benin",
            "prefix": "229"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BM-128.png",
            "iso2": "BM",
            "name": "Bermuda",
            "prefix": "1441"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BT-128.png",
            "iso2": "BT",
            "name": "Bhutan",
            "prefix": "975"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BO-128.png",
            "iso2": "BO",
            "name": "Bolivia, Plurinational State of",
            "prefix": "591"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BQ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BQ-128.png",
            "iso2": "BQ",
            "name": "Bonaire, Sint Eustatius and Saba",
            "prefix": "599"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BA-128.png",
            "iso2": "BA",
            "name": "Bosnia and Herzegovina",
            "prefix": "387"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BW-128.png",
            "iso2": "BW",
            "name": "Botswana",
            "prefix": "267"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BV-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BV-128.png",
            "iso2": "BV",
            "name": "Bouvet Island",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BR-128.png",
            "iso2": "BR",
            "name": "Brazil",
            "prefix": "55"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IO-128.png",
            "iso2": "IO",
            "name": "British Indian Ocean Territory",
            "prefix": "246"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BN-128.png",
            "iso2": "BN",
            "name": "Brunei Darussalam",
            "prefix": "673"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BG-128.png",
            "iso2": "BG",
            "name": "Bulgaria",
            "prefix": "359"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BF-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BF-128.png",
            "iso2": "BF",
            "name": "Burkina Faso",
            "prefix": "226"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BI-128.png",
            "iso2": "BI",
            "name": "Burundi",
            "prefix": "257"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CV-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CV-128.png",
            "iso2": "CV",
            "name": "Cabo Verde",
            "prefix": "238"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KH-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KH-128.png",
            "iso2": "KH",
            "name": "Cambodia",
            "prefix": "855"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CM-128.png",
            "iso2": "CM",
            "name": "Cameroon",
            "prefix": "237"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CA-128.png",
            "iso2": "CA",
            "name": "Canada",
            "prefix": "1"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KY-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KY-128.png",
            "iso2": "KY",
            "name": "Cayman Islands",
            "prefix": "1345"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CF-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CF-128.png",
            "iso2": "CF",
            "name": "Central African Republic",
            "prefix": "236"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TD-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TD-128.png",
            "iso2": "TD",
            "name": "Chad",
            "prefix": "235"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CL-128.png",
            "iso2": "CL",
            "name": "Chile",
            "prefix": "56"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CN-128.png",
            "iso2": "CN",
            "name": "China",
            "prefix": "86"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CX-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CX-128.png",
            "iso2": "CX",
            "name": "Christmas Island",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CC-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CC-128.png",
            "iso2": "CC",
            "name": "Cocos (Keeling) Islands",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CO-128.png",
            "iso2": "CO",
            "name": "Colombia",
            "prefix": "57"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KM-128.png",
            "iso2": "KM",
            "name": "Comoros",
            "prefix": "269"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CG-128.png",
            "iso2": "CG",
            "name": "Congo",
            "prefix": "242"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CD-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CD-128.png",
            "iso2": "CD",
            "name": "Congo, the Democratic Republic of the",
            "prefix": "243"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CK-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CK-128.png",
            "iso2": "CK",
            "name": "Cook Islands",
            "prefix": "682"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CR-128.png",
            "iso2": "CR",
            "name": "Costa Rica",
            "prefix": "506"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CI-128.png",
            "iso2": "CI",
            "name": "Cote d'Ivoire",
            "prefix": "225"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/HR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/HR-128.png",
            "iso2": "HR",
            "name": "Croatia",
            "prefix": "385"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CU-128.png",
            "iso2": "CU",
            "name": "Cuba",
            "prefix": "53"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CW-128.png",
            "iso2": "CW",
            "name": "Curacao",
            "prefix": "5999"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CY-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CY-128.png",
            "iso2": "CY",
            "name": "Cyprus",
            "prefix": "357"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CZ-128.png",
            "iso2": "CZ",
            "name": "Czech Republic",
            "prefix": "420"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/DK-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/DK-128.png",
            "iso2": "DK",
            "name": "Denmark",
            "prefix": "45"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/DJ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/DJ-128.png",
            "iso2": "DJ",
            "name": "Djibouti",
            "prefix": "253"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/DM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/DM-128.png",
            "iso2": "DM",
            "name": "Dominica",
            "prefix": "1767"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/DO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/DO-128.png",
            "iso2": "DO",
            "name": "Dominican Republic",
            "prefix": "1"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/EC-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/EC-128.png",
            "iso2": "EC",
            "name": "Ecuador",
            "prefix": "593"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/EG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/EG-128.png",
            "iso2": "EG",
            "name": "Egypt",
            "prefix": "20"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SV-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SV-128.png",
            "iso2": "SV",
            "name": "El Salvador",
            "prefix": "503"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GQ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GQ-128.png",
            "iso2": "GQ",
            "name": "Equatorial Guinea",
            "prefix": "240"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ER-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ER-128.png",
            "iso2": "ER",
            "name": "Eritrea",
            "prefix": "291"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/EE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/EE-128.png",
            "iso2": "EE",
            "name": "Estonia",
            "prefix": "372"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ET-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ET-128.png",
            "iso2": "ET",
            "name": "Ethiopia",
            "prefix": "251"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/FK-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/FK-128.png",
            "iso2": "FK",
            "name": "Falkland Islands (Malvinas)",
            "prefix": "500"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/FO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/FO-128.png",
            "iso2": "FO",
            "name": "Faroe Islands",
            "prefix": "298"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/FJ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/FJ-128.png",
            "iso2": "FJ",
            "name": "Fiji",
            "prefix": "679"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/FI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/FI-128.png",
            "iso2": "FI",
            "name": "Finland",
            "prefix": "358"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/FR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/FR-128.png",
            "iso2": "FR",
            "name": "France",
            "prefix": "33"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GF-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GF-128.png",
            "iso2": "GF",
            "name": "French Guiana",
            "prefix": "594"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PF-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PF-128.png",
            "iso2": "PF",
            "name": "French Polynesia",
            "prefix": "689"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TF-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TF-128.png",
            "iso2": "TF",
            "name": "French Southern Territories",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GA-128.png",
            "iso2": "GA",
            "name": "Gabon",
            "prefix": "241"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GM-128.png",
            "iso2": "GM",
            "name": "Gambia",
            "prefix": "220"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GE-128.png",
            "iso2": "GE",
            "name": "Georgia",
            "prefix": "995"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/DE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/DE-128.png",
            "iso2": "DE",
            "name": "Germany",
            "prefix": "49"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GH-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GH-128.png",
            "iso2": "GH",
            "name": "Ghana",
            "prefix": "233"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GI-128.png",
            "iso2": "GI",
            "name": "Gibraltar",
            "prefix": "350"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GR-128.png",
            "iso2": "GR",
            "name": "Greece",
            "prefix": "30"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GL-128.png",
            "iso2": "GL",
            "name": "Greenland",
            "prefix": "299"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GD-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GD-128.png",
            "iso2": "GD",
            "name": "Grenada",
            "prefix": "1473"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GP-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GP-128.png",
            "iso2": "GP",
            "name": "Guadeloupe",
            "prefix": "590"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GU-128.png",
            "iso2": "GU",
            "name": "Guam",
            "prefix": "1671"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GT-128.png",
            "iso2": "GT",
            "name": "Guatemala",
            "prefix": "502"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GG-128.png",
            "iso2": "GG",
            "name": "Guernsey",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GN-128.png",
            "iso2": "GN",
            "name": "Guinea",
            "prefix": "224"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GW-128.png",
            "iso2": "GW",
            "name": "Guinea-Bissau",
            "prefix": "245"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GY-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GY-128.png",
            "iso2": "GY",
            "name": "Guyana",
            "prefix": "592"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/HT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/HT-128.png",
            "iso2": "HT",
            "name": "Haiti",
            "prefix": "509"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/HM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/HM-128.png",
            "iso2": "HM",
            "name": "Heard Island and McDonald Islands",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/VA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/VA-128.png",
            "iso2": "VA",
            "name": "Holy See (Vatican City State)",
            "prefix": "379"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/HN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/HN-128.png",
            "iso2": "HN",
            "name": "Honduras",
            "prefix": "504"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/HK-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/HK-128.png",
            "iso2": "HK",
            "name": "Hong Kong",
            "prefix": "852"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/HU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/HU-128.png",
            "iso2": "HU",
            "name": "Hungary",
            "prefix": "36"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IS-128.png",
            "iso2": "IS",
            "name": "Iceland",
            "prefix": "354"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IN-128.png",
            "iso2": "IN",
            "name": "India",
            "prefix": "91"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ID-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ID-128.png",
            "iso2": "ID",
            "name": "Indonesia",
            "prefix": "62"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IR-128.png",
            "iso2": "IR",
            "name": "Iran, Islamic Republic of",
            "prefix": "98"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IQ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IQ-128.png",
            "iso2": "IQ",
            "name": "Iraq",
            "prefix": "964"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IE-128.png",
            "iso2": "IE",
            "name": "Ireland",
            "prefix": "353"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IM-128.png",
            "iso2": "IM",
            "name": "Isle of Man",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IL-128.png",
            "iso2": "IL",
            "name": "Israel",
            "prefix": "972"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/IT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/IT-128.png",
            "iso2": "IT",
            "name": "Italy",
            "prefix": "39"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/JM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/JM-128.png",
            "iso2": "JM",
            "name": "Jamaica",
            "prefix": "1876"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/JP-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/JP-128.png",
            "iso2": "JP",
            "name": "Japan",
            "prefix": "81"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/JE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/JE-128.png",
            "iso2": "JE",
            "name": "Jersey",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/JO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/JO-128.png",
            "iso2": "JO",
            "name": "Jordan",
            "prefix": "962"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KZ-128.png",
            "iso2": "KZ",
            "name": "Kazakhstan",
            "prefix": "7"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KE-128.png",
            "iso2": "KE",
            "name": "Kenya",
            "prefix": "254"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KI-128.png",
            "iso2": "KI",
            "name": "Kiribati",
            "prefix": "686"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KP-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KP-128.png",
            "iso2": "KP",
            "name": "Korea, Democratic People's Republic of",
            "prefix": "850"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KR-128.png",
            "iso2": "KR",
            "name": "Korea, Republic of",
            "prefix": "82"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KW-128.png",
            "iso2": "KW",
            "name": "Kuwait",
            "prefix": "965"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KG-128.png",
            "iso2": "KG",
            "name": "Kyrgyzstan",
            "prefix": "996"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LA-128.png",
            "iso2": "LA",
            "name": "Lao People's Democratic Republic",
            "prefix": "856"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LV-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LV-128.png",
            "iso2": "LV",
            "name": "Latvia",
            "prefix": "371"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LB-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LB-128.png",
            "iso2": "LB",
            "name": "Lebanon",
            "prefix": "961"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LS-128.png",
            "iso2": "LS",
            "name": "Lesotho",
            "prefix": "266"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LR-128.png",
            "iso2": "LR",
            "name": "Liberia",
            "prefix": "231"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LY-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LY-128.png",
            "iso2": "LY",
            "name": "Libya",
            "prefix": "218"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LI-128.png",
            "iso2": "LI",
            "name": "Liechtenstein",
            "prefix": "423"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LT-128.png",
            "iso2": "LT",
            "name": "Lithuania",
            "prefix": "370"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LU-128.png",
            "iso2": "LU",
            "name": "Luxembourg",
            "prefix": "352"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MO-128.png",
            "iso2": "MO",
            "name": "Macao",
            "prefix": "853"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MK-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MK-128.png",
            "iso2": "MK",
            "name": "Macedonia, the former Yugoslav Republic of",
            "prefix": "389"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MG-128.png",
            "iso2": "MG",
            "name": "Madagascar",
            "prefix": "261"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MW-128.png",
            "iso2": "MW",
            "name": "Malawi",
            "prefix": "265"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MY-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MY-128.png",
            "iso2": "MY",
            "name": "Malaysia",
            "prefix": "60"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MV-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MV-128.png",
            "iso2": "MV",
            "name": "Maldives",
            "prefix": "960"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ML-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ML-128.png",
            "iso2": "ML",
            "name": "Mali",
            "prefix": "223"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MT-128.png",
            "iso2": "MT",
            "name": "Malta",
            "prefix": "356"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MH-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MH-128.png",
            "iso2": "MH",
            "name": "Marshall Islands",
            "prefix": "692"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MQ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MQ-128.png",
            "iso2": "MQ",
            "name": "Martinique",
            "prefix": "596"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MR-128.png",
            "iso2": "MR",
            "name": "Mauritania",
            "prefix": "222"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MU-128.png",
            "iso2": "MU",
            "name": "Mauritius",
            "prefix": "230"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/YT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/YT-128.png",
            "iso2": "YT",
            "name": "Mayotte",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MX-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MX-128.png",
            "iso2": "MX",
            "name": "Mexico",
            "prefix": "52"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/FM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/FM-128.png",
            "iso2": "FM",
            "name": "Micronesia, Federated States of",
            "prefix": "691"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MD-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MD-128.png",
            "iso2": "MD",
            "name": "Moldova, Republic of",
            "prefix": "373"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MC-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MC-128.png",
            "iso2": "MC",
            "name": "Monaco",
            "prefix": "377"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MN-128.png",
            "iso2": "MN",
            "name": "Mongolia",
            "prefix": "976"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ME-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ME-128.png",
            "iso2": "ME",
            "name": "Montenegro",
            "prefix": "382"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MS-128.png",
            "iso2": "MS",
            "name": "Montserrat",
            "prefix": "1664"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MA-128.png",
            "iso2": "MA",
            "name": "Morocco",
            "prefix": "212"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MZ-128.png",
            "iso2": "MZ",
            "name": "Mozambique",
            "prefix": "258"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MM-128.png",
            "iso2": "MM",
            "name": "Myanmar",
            "prefix": "95"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NA-128.png",
            "iso2": "NA",
            "name": "Namibia",
            "prefix": "264"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NR-128.png",
            "iso2": "NR",
            "name": "Nauru",
            "prefix": "674"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NP-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NP-128.png",
            "iso2": "NP",
            "name": "Nepal",
            "prefix": "977"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NL-128.png",
            "iso2": "NL",
            "name": "Netherlands",
            "prefix": "31"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NC-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NC-128.png",
            "iso2": "NC",
            "name": "New Caledonia",
            "prefix": "687"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NZ-128.png",
            "iso2": "NZ",
            "name": "New Zealand",
            "prefix": "64"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NI-128.png",
            "iso2": "NI",
            "name": "Nicaragua",
            "prefix": "505"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NE-128.png",
            "iso2": "NE",
            "name": "Niger",
            "prefix": "227"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NG-128.png",
            "iso2": "NG",
            "name": "Nigeria",
            "prefix": "234"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NU-128.png",
            "iso2": "NU",
            "name": "Niue",
            "prefix": "683"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NF-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NF-128.png",
            "iso2": "NF",
            "name": "Norfolk Island",
            "prefix": "672"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MP-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MP-128.png",
            "iso2": "MP",
            "name": "Northern Mariana Islands",
            "prefix": "1670"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/NO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/NO-128.png",
            "iso2": "NO",
            "name": "Norway",
            "prefix": "47"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/OM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/OM-128.png",
            "iso2": "OM",
            "name": "Oman",
            "prefix": "968"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PK-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PK-128.png",
            "iso2": "PK",
            "name": "Pakistan",
            "prefix": "92"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PW-128.png",
            "iso2": "PW",
            "name": "Palau",
            "prefix": "680"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PS-128.png",
            "iso2": "PS",
            "name": "Palestine, State of",
            "prefix": "970"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PA-128.png",
            "iso2": "PA",
            "name": "Panama",
            "prefix": "507"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PG-128.png",
            "iso2": "PG",
            "name": "Papua New Guinea",
            "prefix": "675"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PY-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PY-128.png",
            "iso2": "PY",
            "name": "Paraguay",
            "prefix": "595"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PE-128.png",
            "iso2": "PE",
            "name": "Peru",
            "prefix": "51"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PH-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PH-128.png",
            "iso2": "PH",
            "name": "Philippines",
            "prefix": "63"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PN-128.png",
            "iso2": "PN",
            "name": "Pitcairn",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PL-128.png",
            "iso2": "PL",
            "name": "Poland",
            "prefix": "48"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PT-128.png",
            "iso2": "PT",
            "name": "Portugal",
            "prefix": "351"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PR-128.png",
            "iso2": "PR",
            "name": "Puerto Rico",
            "prefix": "1787"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/QA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/QA-128.png",
            "iso2": "QA",
            "name": "Qatar",
            "prefix": "974"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/RE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/RE-128.png",
            "iso2": "RE",
            "name": "Reunion",
            "prefix": "262"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/RO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/RO-128.png",
            "iso2": "RO",
            "name": "Romania",
            "prefix": "40"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/RU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/RU-128.png",
            "iso2": "RU",
            "name": "Russian Federation",
            "prefix": "7"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/RW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/RW-128.png",
            "iso2": "RW",
            "name": "Rwanda",
            "prefix": "250"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/BL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/BL-128.png",
            "iso2": "BL",
            "name": "Saint Barthelemy",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SH-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SH-128.png",
            "iso2": "SH",
            "name": "Saint Helena, Ascension and Tristan da Cunha",
            "prefix": "290"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/KN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/KN-128.png",
            "iso2": "KN",
            "name": "Saint Kitts and Nevis",
            "prefix": "1869"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LC-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LC-128.png",
            "iso2": "LC",
            "name": "Saint Lucia",
            "prefix": "1758"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/MF-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/MF-128.png",
            "iso2": "MF",
            "name": "Saint Martin (French part)",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/PM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/PM-128.png",
            "iso2": "PM",
            "name": "Saint Pierre and Miquelon",
            "prefix": "508"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/VC-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/VC-128.png",
            "iso2": "VC",
            "name": "Saint Vincent and the Grenadines",
            "prefix": "1784"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/WS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/WS-128.png",
            "iso2": "WS",
            "name": "Samoa",
            "prefix": "685"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SM-128.png",
            "iso2": "SM",
            "name": "San Marino",
            "prefix": "378"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ST-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ST-128.png",
            "iso2": "ST",
            "name": "Sao Tome and Principe",
            "prefix": "239"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SA-128.png",
            "iso2": "SA",
            "name": "Saudi Arabia",
            "prefix": "966"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SN-128.png",
            "iso2": "SN",
            "name": "Senegal",
            "prefix": "221"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/RS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/RS-128.png",
            "iso2": "RS",
            "name": "Serbia",
            "prefix": "381"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SC-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SC-128.png",
            "iso2": "SC",
            "name": "Seychelles",
            "prefix": "248"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SL-128.png",
            "iso2": "SL",
            "name": "Sierra Leone",
            "prefix": "232"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SG-128.png",
            "iso2": "SG",
            "name": "Singapore",
            "prefix": "65"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SX-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SX-128.png",
            "iso2": "SX",
            "name": "Sint Maarten (Dutch part)",
            "prefix": "1721"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SK-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SK-128.png",
            "iso2": "SK",
            "name": "Slovakia",
            "prefix": "421"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SI-128.png",
            "iso2": "SI",
            "name": "Slovenia",
            "prefix": "386"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SB-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SB-128.png",
            "iso2": "SB",
            "name": "Solomon Islands",
            "prefix": "677"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SO-128.png",
            "iso2": "SO",
            "name": "Somalia",
            "prefix": "252"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ZA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ZA-128.png",
            "iso2": "ZA",
            "name": "South Africa",
            "prefix": "27"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GS-128.png",
            "iso2": "GS",
            "name": "South Georgia and the South Sandwich Islands",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SS-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SS-128.png",
            "iso2": "SS",
            "name": "South Sudan",
            "prefix": "211"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ES-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ES-128.png",
            "iso2": "ES",
            "name": "Spain",
            "prefix": "34"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/LK-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/LK-128.png",
            "iso2": "LK",
            "name": "Sri Lanka",
            "prefix": "94"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SD-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SD-128.png",
            "iso2": "SD",
            "name": "Sudan",
            "prefix": "249"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SR-128.png",
            "iso2": "SR",
            "name": "Suriname",
            "prefix": "597"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SJ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SJ-128.png",
            "iso2": "SJ",
            "name": "Svalbard and Jan Mayen",
            "prefix": "4779"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SZ-128.png",
            "iso2": "SZ",
            "name": "Swaziland",
            "prefix": "268"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SE-128.png",
            "iso2": "SE",
            "name": "Sweden",
            "prefix": "46"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/CH-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/CH-128.png",
            "iso2": "CH",
            "name": "Switzerland",
            "prefix": "41"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/SY-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/SY-128.png",
            "iso2": "SY",
            "name": "Syrian Arab Republic",
            "prefix": "963"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TW-128.png",
            "iso2": "TW",
            "name": "Taiwan, Province of China",
            "prefix": "886"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TJ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TJ-128.png",
            "iso2": "TJ",
            "name": "Tajikistan",
            "prefix": "992"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TZ-128.png",
            "iso2": "TZ",
            "name": "Tanzania, United Republic of",
            "prefix": "255"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TH-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TH-128.png",
            "iso2": "TH",
            "name": "Thailand",
            "prefix": "66"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TL-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TL-128.png",
            "iso2": "TL",
            "name": "Timor-Leste",
            "prefix": "670"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TG-128.png",
            "iso2": "TG",
            "name": "Togo",
            "prefix": "228"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TK-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TK-128.png",
            "iso2": "TK",
            "name": "Tokelau",
            "prefix": "690"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TO-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TO-128.png",
            "iso2": "TO",
            "name": "Tonga",
            "prefix": "676"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TT-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TT-128.png",
            "iso2": "TT",
            "name": "Trinidad and Tobago",
            "prefix": "1868"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TN-128.png",
            "iso2": "TN",
            "name": "Tunisia",
            "prefix": "216"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TR-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TR-128.png",
            "iso2": "TR",
            "name": "Turkey",
            "prefix": "90"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TM-128.png",
            "iso2": "TM",
            "name": "Turkmenistan",
            "prefix": "993"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TC-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TC-128.png",
            "iso2": "TC",
            "name": "Turks and Caicos Islands",
            "prefix": "1649"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/TV-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/TV-128.png",
            "iso2": "TV",
            "name": "Tuvalu",
            "prefix": "688"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/UG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/UG-128.png",
            "iso2": "UG",
            "name": "Uganda",
            "prefix": "256"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/UA-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/UA-128.png",
            "iso2": "UA",
            "name": "Ukraine",
            "prefix": "380"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/AE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/AE-128.png",
            "iso2": "AE",
            "name": "United Arab Emirates",
            "prefix": "971"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GB-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GB-128.png",
            "iso2": "GB",
            "name": "United Kingdom",
            "prefix": "44"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/GB-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/GB-128.png",
            "iso2": "UK",
            "name": "United Kingdom",
            "prefix": "44"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/US-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/US-128.png",
            "iso2": "US",
            "name": "United States",
            "prefix": "1"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/UM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/UM-128.png",
            "iso2": "UM",
            "name": "United States Minor Outlying Islands",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/UY-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/UY-128.png",
            "iso2": "UY",
            "name": "Uruguay",
            "prefix": "598"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/UZ-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/UZ-128.png",
            "iso2": "UZ",
            "name": "Uzbekistan",
            "prefix": "998"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/VU-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/VU-128.png",
            "iso2": "VU",
            "name": "Vanuatu",
            "prefix": "678"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/VE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/VE-128.png",
            "iso2": "VE",
            "name": "Venezuela, Bolivarian Republic of",
            "prefix": "58"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/VN-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/VN-128.png",
            "iso2": "VN",
            "name": "Viet Nam",
            "prefix": "84"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/VG-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/VG-128.png",
            "iso2": "VG",
            "name": "Virgin Islands, British",
            "prefix": "1284"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/VI-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/VI-128.png",
            "iso2": "VI",
            "name": "Virgin Islands, U.S.",
            "prefix": "1340"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/WF-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/WF-128.png",
            "iso2": "WF",
            "name": "Wallis and Futuna",
            "prefix": "681"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/EH-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/EH-128.png",
            "iso2": "EH",
            "name": "Western Sahara",
            "prefix": null
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/YE-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/YE-128.png",
            "iso2": "YE",
            "name": "Yemen",
            "prefix": "967"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ZM-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ZM-128.png",
            "iso2": "ZM",
            "name": "Zambia",
            "prefix": "260"
        },
        {
            "flag_32": "https://checkmobi.com/static/images/flags/ZW-32.png",
            "flag_128": "https://checkmobi.com/static/images/flags/ZW-128.png",
            "iso2": "ZW",
            "name": "Zimbabwe",
            "prefix": "263"
        }
    ];

    pluralToSingularCorrespondence = {
        'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.dob.plural': 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.dob.singular',
        'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.dob.plural': 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.dob.singular'
    };

    breachActions = {
        [ActionType.CHANGE_PASSWORD]: {
            [BreachType.BREACH]: {
                title: 'privacy.breach.actions.change_password.new',
                titleDone: 'privacy.breach.actions.change_password.done',
                icon: 'passwords'
            },
            [BreachType.COLLECTION]: {
                title: 'privacy.breach.actions.change_password.new.with.email',
                titleDone: 'privacy.breach.actions.change_password.done.with.email',
                icon: 'passwords'
            }
        },
        [ActionType.ACTIVATE_2FA]: {
            [BreachType.BREACH]: {
                title: 'privacy.breach.actions.activate_2fa.new',
                titleDone: 'privacy.breach.actions.activate_2fa.done',
                icon: '2fa'
            },
            [BreachType.COLLECTION]: {
                title: 'privacy.breach.actions.activate_2fa.new.with.email',
                titleDone: 'privacy.breach.actions.activate_2fa.done.with.email',
                icon: '2fa'
            }
        },
        [ActionType.DELETE_ACCOUNT]: {
            [BreachType.BREACH]: {
                title: 'privacy.breach.actions.delete',
                titleDone: 'privacy.breach.actions.deleteAccount.done',
                descriptionDone: 'privacy.breach.actions.deletedAccount.description',
                icon: 'erase'
            }
        },
        [ActionType.NEVER_USED_SERVICE]: {
            [BreachType.BREACH]: {
                title: 'privacy.breach.actions.clear',
                titleDone: 'privacy.breach.actions.neverUsed.done',
                descriptionDone: 'privacy.breach.actions.neverUsed.description',
                scoreLeftOut: 'privacy.breaches.actions.score.leftOut',
                icon: 'question-circle'
            }
        },
        [ActionType.CONTACT_BANK]: {
            [BreachType.BREACH]: {
                title: 'privacy.breach.actions.contact_bank',
                description: 'privacy.breach.actions.contact_bank.description',
                titleDone: 'privacy.breach.actions.contact_bank.done',
                icon: 'credit-data'
            }
        },
        [ActionType.CONTACT_AUTHORITIES]: {
            [BreachType.BREACH]: {
                title: 'privacy.breach.actions.contact_authorities',
                description: 'privacy.breach.actions.contact_authorities.description',
                titleDone: 'privacy.breach.actions.contact_authorities.done',
                icon: 'priority-support'
            }
        }
    };

    breachesScoreText = {
        [IssueActionStatus.NEW]: {
            one: 'privacy.breach.protection.score.new.one',
            multiple: 'privacy.breach.protection.score.new.multiple',
        },
        [IssueActionStatus.DONE]: {
            one: 'privacy.breach.protection.score.complete.one',
            multiple: 'privacy.breach.protection.score.complete.multiple',
        },
        [IssueActionStatus.PROGRESS]: {
            multiple: 'privacy.breach.protection.score.in.progress'
        }
    };

    transformingEventsType = {
        [PrivacyIssueType.IMPERSONATION]: ActionType.CONFIRM_IMPERSONATION,
        [PrivacyIssueType.BREACH]: ActionType.ACTIVATE_2FA,
        [PrivacyIssueType.EXPOSURE]: ActionType.CONFIRM_EXPOSURE,
    };


    // breach and impersonations have subtype
    // exposure do not have subtype
    privacyEventsModule = {
        [PrivacyEventsTypes.IMPERSONATION]: {
            [PrivacyEventsSubtypes.DEFAULT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.impersonation.default.title'
                },
                [EventDescriptors.TITLE_STANDARD]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.impersonation.default.title.standard'
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.impersonation.default.description',
                },
                [EventDescriptors.DESC_STANDARD]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.impersonation.default.description.standard',
                },
                [EventDescriptors.WHATTODO_TITLE]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.impersonation.default.description.whatToDo.title'
                },
                [EventDescriptors.WHATTODO_TITLE_STANDARD]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.impersonation.default.description.whatToDo.title.standard'
                },
                [EventDescriptors.WHATTODO_DESC]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.impersonation.default.description.whatToDo.desc'
                },
                [EventDescriptors.ACTION_TITLE]: {
                    [EventDescriptors.CONDITION]: EventConditions.TYPE,
                    [EventDescriptors.TYPE]: EventTypes.CONDITIONAL,
                    incorrect_profile_removal_request: 'privacy.notif.impersonation.default.action_title.incorrect_profile_removal_request',
                    fake_profile_remediation: 'privacy.notif.impersonation.default.action_title.incorrect_profile_removal_request',
                }
            }
        },
        [PrivacyEventsTypes.BREACH]: {
            [PrivacyEventsSubtypes.DEFAULT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.breach.default.title'
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.CONDITION]: EventConditions.DESC_TYPE,
                    [EventDescriptors.TYPE]: EventTypes.CONDITIONAL,
                    [PrivacyDescTypes.BREACH_WITH_NO_HELP_LINK_STANDARD]: 'privacy.notif.breach.default.description.breach.noHelpLink.standard',
                    [PrivacyDescTypes.DATA_COLLECTION_WITH_NO_HELP_LINK]: 'privacy.notif.breach.default.description.dataCollectionWithNoHelpLink'
                },
                [EventDescriptors.ACTION_TITLE]: {
                    [EventDescriptors.CONDITION]: EventConditions.TYPE,
                    [EventDescriptors.TYPE]: EventTypes.CONDITIONAL,
                    change_password: 'privacy.notif.breach.default.action_title.change_password',
                    two_factor_authentication: 'privacy.notif.breach.default.action_title.two_factor_authentication',
                    activate_2fa: 'privacy.notif.breach.default.action_title.two_factor_authentication',
                    contact_bank: 'privacy.notif.breach.default.action_title.contact_bank',
                    contact_authorities: 'privacy.notif.breach.default.action_title.contact_authorities'
                }
            }
        },
        [PrivacyEventsTypes.RECORD]: {
            [PrivacyEventsSubtypes.DEFAULT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.record.default.title'
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'privacy.notif.record.default.description'
                }
            }
        },
        [PrivacyEventsTypes.CREATE]: {
            [EventDescriptors.TITLE]: {
                [EventDescriptors.TEXT]: 'privacy.notif.create.title'
            },
            [EventDescriptors.DESC]: {
                [EventDescriptors.TEXT]: 'privacy.notif.create.description'
            }
        },
        [PrivacyEventsTypes.ISSUE_ADDED]: {
            [EventDescriptors.TITLE]: {
                [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                [EventDescriptors.CONDITION]: ['event_trigger', 'object_type', 'object_value'],
                cloud_init: {
                    exposure: 'privacy.notif.issue_added.cloud_init.exposure.title',
                    breach: 'privacy.notif.issue_added.cloud_init.breach.title',
                    impersonation: 'privacy.notif.issue_added.cloud_init.impersonation.title'
                },
                cloud_alert: {
                    exposure: 'privacy.notif.issue_added.cloud_alert.exposure.title',
                    breach: 'privacy.notif.issue_added.cloud_alert.breach.title',
                    impersonation: 'privacy.notif.issue_added.cloud_alert.impersonation.title'
                }
            },
            [EventDescriptors.DESC]: {
                [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                [EventDescriptors.CONDITION]: ['event_trigger', 'object_type', 'object_value'],
                cloud_init: {
                    exposure: 'privacy.notif.issue_added.cloud_init.exposure.description',
                    breach: {
                        multiple: 'privacy.notif.issue_added.cloud_init.breach.multiple.description',
                        singular: 'privacy.notif.issue_added.cloud_init.breach.singular.description'
                    },
                    impersonation: {
                        multiple: 'privacy.notif.issue_added.cloud_init.impersonation.multiple.description',
                        singular: 'privacy.notif.issue_added.cloud_init.impersonation.singular.description'
                    }
                },
                cloud_alert: {
                    exposure: 'privacy.notif.issue_added.cloud_alert.exposure.description',
                    breach: 'privacy.notif.issue_added.cloud_alert.breach.description',
                    impersonation: 'privacy.notif.issue_added.cloud_alert.impersonation.description'
                }
            }
        },
        [PrivacyEventsTypes.SET_ACTION_STATUS]: {
            [EventDescriptors.TITLE]: {
                [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                [EventDescriptors.CONDITION]: ['object_type', 'action_type', 'action_value'],
                impersonation: {
                    confirm_impersonation: {
                        confirmed_impersonation: 'privacy.notif.set.impersonation.confirm_impersonation.confirmed_impersonation.title',
                        confirmed_personal_profile: 'privacy.notif.set.impersonation.confirm_impersonation.confirmed_personal_profile.title',
                        confirmed_something_else: 'privacy.notif.set.impersonation.confirm_impersonation.confirmed_personal_profile.title',
                        undo: 'privacy.notif.set_action_status.impersonation.confirm_impersonation.undo.title'
                    },
                    fake_profile_remediation: {
                        undo: 'privacy.notif.set_action_status.impersonation.confirm_impersonation.undo.title',
                        done: 'privacy.notif.set_action_status.impersonation.fake_profile_remediation.done.title',
                    }
                },
                breach: {
                    activate_2fa: {
                        done: 'privacy.notif.set_action_status.impersonation.fake_profile_remediation.done.title',
                        undo: 'privacy.notif.set_action_status.impersonation.confirm_impersonation.undo.title',
                    },
                    change_password: {
                        undo: 'privacy.notif.set_action_status.impersonation.confirm_impersonation.undo.title',
                        done: 'privacy.notif.set_action_status.impersonation.fake_profile_remediation.done.title',
                    }
                },
                exposure: {
                    confirm_exposure: {
                        confirmed: 'privacy.notif.set_action_status.exposure.confirmed.title',
                        undo: 'privacy.notif.set_action_status.impersonation.confirm_impersonation.undo.title',
                        rejected: 'privacy.notif.set_action_status.exposure.rejected.title'
                    }
                }
            },
            [EventDescriptors.DESC]: {
                [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                [EventDescriptors.CONDITION]: ['object_type', 'action_type', 'action_value', 'object_subtype', 'object_value'],
                impersonation: {
                    confirm_impersonation: {
                        confirmed_impersonation: 'privacy.notif.set.impersonation.confirm_impersonation.confirmed_impersonation.desc',
                        confirmed_personal_profile: 'privacy.notif.set.impersonation.confirm_impersonation.confirmed_personal_profile.desc',
                        confirmed_something_else: 'privacy.notif.set.impersonation.confirm_impersonation.confirmed_something_else.desc',
                        undo: 'privacy.notif.set_action_status.impersonation.confirm_impersonation.undo.description'
                    },
                    fake_profile_remediation: {
                        done: 'privacy.notif.set_action_status.impersonation.fake_profile_remediation.done.description',
                        undo: 'privacy.notif.set_action_status.impersonation.confirm_impersonation.undo.description'
                    },
                    undo: 'privacy.notif.set_action_status.impersonation.confirm_impersonation.undo.description'
                },
                breach: {
                    activate_2fa: {
                        done: 'privacy.notif.set_action_status.breach.activate_2fa.done.description',
                        undo: 'privacy.notif.set_action_status.breach.activate_2fa.undo.description',
                    },
                    change_password: {
                        done: 'privacy.notif.set_action_status.breach.change_password.done.description',
                        undo: 'privacy.notif.set_action_status.breach.activate_2fa.undo.description',
                    },
                    undo: 'privacy.notif.set_action_status.breach.activate_2fa.undo.description',
                },
                exposure: {
                    confirm_exposure: {
                        confirmed: {
                            gender: {
                                female: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.gender.female',
                                male: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.gender.male',
                            },
                            addresses: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.addresses',
                            dob: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.dob.plural',
                            educations: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.educations',
                            emails: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.emails',
                            images: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.images',
                            jobs: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.jobs',
                            names: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.names',
                            phones: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.phones',
                            urls: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.urls',
                            user_ids: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.user_ids',
                            usernames: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.usernames',
                            languages: 'privacy.notif.set_action_status.exposure_confirmation.desc.confirmed.languages'
                        },
                        undo: 'privacy.notif.set_action_status.exposure.undo.description',
                        rejected: {
                            gender: {
                                female: 'privacy.notif.set_action_status.exposure.rejected.female.description',
                                male: 'privacy.notif.set_action_status.exposure.rejected.male.description',
                            },
                            addresses: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.addresses',
                            dob: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.dob.plural',
                            educations: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.educations',
                            emails: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.emails',
                            images: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.images',
                            jobs: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.jobs',
                            names: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.names',
                            phones: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.phones',
                            urls: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.urls',
                            user_ids: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.user_ids',
                            usernames: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.usernames',
                            languages: 'privacy.notif.set_action_status.exposure_confirmation.desc.rejected.languages'
                        }
                    }
                }
            },
        },
        [PrivacyEventsTypes.ADD_INFO]: {
            [PrivacyEventsSubtypes.FULLNAME]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                    [EventDescriptors.CONDITION]: ['object_source'],
                    ONBOARDING: 'privacy.notif.add_info.fullname.ONBOARDING.title'
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                    [EventDescriptors.CONDITION]: ['object_source'],
                    ONBOARDING: 'privacy.notif.add_info.fullname.ONBOARDING.description'
                }
            },
            [PrivacyEventsSubtypes.EMAILS]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                    [EventDescriptors.CONDITION]: ['object_source'],
                    ONBOARDING: 'privacy.notif.add_info.emails.ONBOARDING.title',
                    USER_ADDED: 'privacy.notif.add_info.emails.ONBOARDING.title',
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                    [EventDescriptors.CONDITION]: ['object_source'],
                    ONBOARDING: 'privacy.notif.add_info.fullname.ONBOARDING.description',
                    USER_ADDED: 'privacy.notif.add_info.fullname.ONBOARDING.description'
                }
            },
            [PrivacyEventsSubtypes.PHONES]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                    [EventDescriptors.CONDITION]: ['object_source'],
                    ONBOARDING: 'privacy.notif.add_info.phones.ONBOARDING.title',
                    USER_ADDED: 'privacy.notif.add_info.phones.ONBOARDING.title',
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                    [EventDescriptors.CONDITION]: ['object_source'],
                    ONBOARDING: 'privacy.notif.add_info.fullname.ONBOARDING.description',
                    USER_ADDED: 'privacy.notif.add_info.fullname.ONBOARDING.description'
                }
            }
        },
        [PrivacyEventsTypes.INIT]: {
            [EventDescriptors.TITLE]: {
                [EventDescriptors.TEXT]: 'privacy.notif.init.title',
            },
            [EventDescriptors.DESC]: {
                [EventDescriptors.TEXT]: 'privacy.notif.init.description'
            }
        },
        [PrivacyEventsTypes.CLOUD_INIT]: {
            [EventDescriptors.TITLE]: {
                [EventDescriptors.TEXT]: 'privacy.notif.cloud_init.title',
            },
            [EventDescriptors.DESC]: {
                [EventDescriptors.TEXT]: 'privacy.notif.cloud_init.description'
            }
        },
        [PrivacyEventsTypes.ISSUE_DONE]: {
            [EventDescriptors.TITLE]: {
                [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                [EventDescriptors.CONDITION]: ['object_type'],
                impersonation: 'privacy.notif.issue_done.impersonation.title',
                breach: 'privacy.notif.issue_done.impersonation.title',
                exposure: 'privacy.notif.issue_done.impersonation.title'
            },
            [EventDescriptors.DESC]: {
                [EventDescriptors.TYPE]: EventTypes.RECURSIVE,
                [EventDescriptors.CONDITION]: ['object_type'],
                impersonation: 'privacy.notif.issue_done.impersonation.description',
                breach: 'privacy.notif.issue_done.breach.description',
                exposure: 'privacy.notif.issue_done.exposure.description'
            }
        },
        [PrivacyEventsTypes.DEFAULT]: {
            [EventDescriptors.TITLE]: {
                [EventDescriptors.TEXT]: ''
            },
            [EventDescriptors.DESC]: {
                [EventDescriptors.TEXT]: ''
            }
        }
    };

    remediationActionToTextCorrespondence = {
        [RemediationActionType.REMOVE_PERSONAL_INFO]: 'privacy.remediation.action.remove_personal_info',
        [RemediationActionType.MANAGE_MENTIONS]: 'privacy.remediation.action.manage_mentions',
        [RemediationActionType.MANAGE_SEARCH]: 'privacy.remediation.action.manage_search',
        [RemediationActionType.MANAGE_PERSONALISATION]: 'privacy.remediation.action.manage_personalisation',
        [RemediationActionType.MANAGE_COOKIES]: 'privacy.remediation.action.manage_cookies',
        [RemediationActionType.DATA_DOWNLOAD_EOS]: 'privacy.remediation.action.data_download_eos',
        [RemediationActionType.MANAGE_SERVICE_INFO]: 'privacy.remediation.action.manage_service_info',
        [RemediationActionType.MANAGE_SETTINGS_PRIVACY]: 'privacy.remediation.action.manage_settings_privacy',
        [RemediationActionType.MANAGE_SETTINGS_ADS]: 'privacy.remediation.action.manage_settings_ads',
        [RemediationActionType.DATA_USAGE]: 'privacy.remediation.action.data_usage',
        [RemediationActionType.DATA_THIRD_PARTY]: 'privacy.remediation.action.data_third_party',
        [RemediationActionType.PREFERENCES_ADS]: 'privacy.remediation.action.preferences_ads',
        [RemediationActionType.MANAGE_SERVICES]: 'privacy.remediation.action.manage_services',
        [RemediationActionType.DATA_COLLECTED]: 'privacy.remediation.action.data_collected',
        [RemediationActionType.PREFERENCES_JOB_SEEKING]: 'privacy.remediation.action.preferences_job_seeking',
        [RemediationActionType.DATA_DOWNLOAD]: 'privacy.remediation.action.data_download',
        [RemediationActionType.DO_NOT_SELL]: 'privacy.remediation.action.do_not_sell',
        [RemediationActionType.MANAGE_BROWSING_HISTORY]: 'privacy.remediation.action.manage_browsing_history',
        [RemediationActionType.MANAGE_ADS_PREFERENCES]: 'privacy.remediation.action.manage_ads_preferences',
        [RemediationActionType.DATA_REQUEST]: 'privacy.remediation.action.data_request',
        [RemediationActionType.DATA_DOWNLOAD_ARCHIVE]: 'privacy.remediation.action.data_download_archive',
        [RemediationActionType.OTHER_DISCOVERABILITY_AND_CONTACTS]: 'privacy.remediation.action.other_discoverability_and_contacts',
        [RemediationActionType.MANAGE_ADD_PREFERENCES]: 'privacy.remediation.action.manage_add_preferences',
        [RemediationActionType.OTHER_DISABLE_ACTIVITY_TRACKING]: 'privacy.remediation.action.other_disable_activity_tracking',
        [RemediationActionType.DATA_DO_NOT_SHARE]: 'privacy.remediation.action.data_do_not_share',
        [RemediationActionType.MANAGE_LOCATION_INFO]: 'privacy.remediation.action.manage_location_info',
        [RemediationActionType.FILTER_PUBLIC_POSTS]: 'privacy.remediation.action.filter_public_posts',
        [RemediationActionType.CHECK_YOUR_SECURITY_SETTINGS]: 'privacy.remediation.action.check_your_security_settings'
    };

    categoriesOrder = [
        MetadataType.NAMES,
        MetadataType.GENDER,
        MetadataType.DOB,
        MetadataType.EMAILS,
        MetadataType.PHONES,
        MetadataType.ADDRESSES,
        MetadataType.USERNAMES,
        MetadataType.JOBS,
        MetadataType.EDUCATIONS,
        // MetadataType.IMAGES,
        MetadataType.USER_IDS,
        MetadataType.URLS,
        // MetadataType.LANGUAGES
    ];

    categoriesDashboard = [
        MetadataType.NAMES,
        MetadataType.GENDER,
        MetadataType.DOB,
        MetadataType.EMAILS,
        MetadataType.PHONES,
        MetadataType.ADDRESSES,
        MetadataType.USERNAMES,
        MetadataType.JOBS,
        MetadataType.EDUCATIONS,
        MetadataType.URLS
    ];

    pages = {
        // 'news_feed': '/privacy/newsfeed',
        // 'digital_footprint': '/privacy/footprint',
        'threats': '/privacy/threats',
        'about': '/privacy/about',
        'activity': '/privacy/activity',
        'digital_footprint': '/privacy/footprint',
        'breaches': '/privacy/breaches',
        'impersonations': '/privacy/impersonations',
        'brokers': '/privacy/brokers',
        'education': '/privacy/education',
        'article': '/privacy/education/article/',
        'history': '/privacy/history',
        'monitor': '/privacy/monitor',
        'main': '/privacy/'
    };

    pageAndAboutModalsSectionsCorrespondence = {
        [this.valuesService.centralPaths.privacy.footprint.id]: AboutModalSections.FOOTPRINT,
        [this.valuesService.centralPaths.privacy.breaches.id]: AboutModalSections.BREACHES,
        [this.valuesService.centralPaths.privacy.impersonations.id]: AboutModalSections.IMPERSONATIONS,
        [this.valuesService.centralPaths.privacy.education.id]: AboutModalSections.EDUCATION,
        [this.valuesService.centralPaths.privacy.history.id]: AboutModalSections.ACTIVITY,
        [this.valuesService.centralPaths.privacy.monitor.id]: AboutModalSections.IDENTITY_MONITOR
    };

    confirmModalsSections = {
        [PrivacyEvents.CONFIRM_BREACH_ACTION]: 'confirmBreachAction'
    };

    emailNotifications = {
        breach: {
            settingName: 'dip/emails/breach',
            label: {
                id: '1596097680974', // am nevoie pentru switchuri
                title: 'privacy.email.alerts.title',
                description: 'privacy.email.alerts.breaches.desc'
            }
        },
        impersonation: {
            settingName: 'dip/emails/impersonation',
            label: {
                id: '1596110179223',
                title: 'privacy.email.alerts.title',
                description: 'privacy.email.alerts.impersonations.desc'
            }
        },
        dp: {
            settingName: 'dip/emails/dp',
            label: {
                id: '1596110207878',
                title: 'privacy.email.alerts.title',
                description: 'privacy.email.alerts.footprint.desc'
            }
        }
    };

    servicesRemediationDescriptions = {
        "twitter": 'privacy.remediation.description.twitter',
        "amazon": 'privacy.remediation.description.amazon',
        "mobilein.in": 'privacy.remediation.description.mobilein',
        "callername": 'privacy.remediation.description.callername',
        "pinterest": 'privacy.remediation.description.pinterest',
        "white pages": 'privacy.remediation.description.whitepages',
        "myspace": 'privacy.remediation.description.myspace',
        "yellow pages": 'privacy.remediation.description.yellowpages',
        "google+": 'privacy.remediation.description.googleplus',
        "instant checkmate": 'privacy.remediation.description.instantcheckmate',
        "facebook": 'privacy.remediation.description.facebook',
        "linkedin": 'privacy.remediation.description.linkedin'
    }

    aboutModalsInfo = {
        activity: 'dip/dialogs/sha', //show history about
        footprint: 'dip/dialogs/sfa', //show footprint about
        education: 'dip/dialogs/sea', //show education about
        breaches: 'dip/dialogs/sba', //show breaches about
        impersonations: 'dip/dialogs/sia', //show impersonations about
        dataMonitor: 'dip/dialogs/sdma', //show data monitor about
        threatConfirmed_record: 'dip/dialogs/crt', //confirm record threat
        threatConfirmed_impersonation: 'dip/dialogs/cit', //confirm impersonation threat
        showImpersonationInfo: 'dip/dialogs/ii', //impersonation info
        confirmBreachAction: 'dip/dialogs/cba', //confirm breach action
        confirmUndoBreachAction: 'dip/dialogs/cuba', //confirm undo breach action
        confirmImpersonationAction: 'dip/dialogs/cia', //confirm impersonation action
        showLinkInfo: 'dip/dialogs/li', //link info
        validateMetadataInfo: 'dip/dialogs/vmi', //validate metadata info
    }

    dataBrokersSettings = {
        dataBrokersLegal: 'dip/databrokers/banner/legal', // banner about legal limitations in Data Brokers tab
        dataBrokersNew: 'dip/databrokers/new' // new label in tab name
    }

    dataMonitorSettings = {
        dataMonitorOnboarding: 'dip/datamonitor/onboarding' // banner with info about data monitor
    }

    generalSettings = {
        defaultBubblesView: 'dip/general/dbv',
    }

    modals = {
        onboarding: 'onboarding-dip',
        manage_data: 'manage-data-modal'
    };

    onboardingModals = new Set([
        ModalName.onboardingStandardEmailModal,
        ModalName.onboardingStandardChoosePhoneModal,
        ModalName.onboardingStandardPhoneModal,
        ModalName.onboardingStandardNameModal
    ]);

    genderTexts = {
        [GenderType.FEMALE]: 'privacy.gender.female',
        [GenderType.MALE]: 'privacy.gender.male'
    };

    onboardingStatus = {
        DONE: 'done',
        IN_PROGRESS: 'in_progress',
        NOT_STARTED: 'not_started'
    };

    identityProtectionHistory = {
        "change_password_undo": {
            text: 'privacy.activity.identity.protection.score.password.undo.description',
            danger: true
        },
        "change_password_done": {
            text: 'privacy.activity.identity.protection.score.password.done.description',
            danger: false
        },
        "activate_2fa_undo": {
            text: 'privacy.activity.identity.protection.score.2fa.undo.description',
            danger: true
        },
        "activate_2fa_done": {
            text: 'privacy.activity.identity.protection.score.2fa.done.description',
            danger: false
        },
        "breach": {
            text: 'privacy.activity.identity.protection.score.breach.description',
            danger: true
        }
    };

    onboardingAboutStep = "1.100";

    onboardingSteps = new Set(['1.1', '1.2', '1.3', '1.4.1', '1.4.2', '1.5.1', '1.5.2']);

    images = {
        mobileChooseIllustration: { link: this.configService.config.pathImages + 'privacy/mobile@2x.png', alt: "TBD" },
        landlineChooseIllustration: { link: this.configService.config.pathImages + 'privacy/landline@2x.png', alt: "TBD" },
        mailIllustration: { link: this.configService.config.pathImages + 'privacy/onboarding/ill_mail@2x.png', alt: "TBD" },
        mobileIllustration: { link: this.configService.config.pathImages + 'privacy/onboarding/ill_phone@2x.png', alt: "TBD" },
        landlineIllustration: { link: this.configService.config.pathImages + 'privacy/onboarding/ill_landline@2x.png', alt: "TBD" },
        howItWorksIllustration: { link: this.configService.config.pathImages + '/privacy/onboarding/ill_how_it_works@2x.png', alt: "TBD" },
        nameIllustration: { link: this.configService.config.pathImages + 'privacy/onboarding/ill_name@2x.png', alt: "TBD" },
        identitySearchIllustration: { link: this.configService.config.pathImages + 'privacy/collecting_data.png', alt: "TBD" },
        noThreatsIllustration: { link: this.configService.config.pathImages + '/illustrations/illustration-no-threats@2x.png', alt: "TBD" },
        hasThreatsIllustration: { link: this.configService.config.pathImages + '/illustrations/illustration-has-threats@2x.png', alt: "TBD" },
        eventHistory: { link: this.configService.config.pathImages + 'privacy/ill_history.svg', alt: "" },
        digitalFootprint: { link: this.configService.config.pathImages + 'privacy/ill_digital_footprint.svg', alt: "" },
        dataBreaches: { link: this.configService.config.pathImages + 'privacy/ill_data_breaches.svg', alt: "" },
        impersonations: { link: this.configService.config.pathImages + 'privacy/ill_impersonations.svg', alt: "" },
        education: { link: this.configService.config.pathImages + 'privacy/ill_education.svg', alt: "" },
        footprintEmptyBreaches: { link: this.configService.config.pathImages.concat('privacy/ill_protected_community.svg'), alt: "" },
        dataMonitoring: { link: this.configService.config.pathImages + 'privacy/ill_data_monitoring.svg', alt: "privacy.dataMonitor.title" },
        identityMonitor: { link: this.configService.config.pathImages + 'privacy/ill_identity_monitor.svg', alt: "privacy.identity.monitor.title" },
        emptyState: { link: this.configService.config.pathImages + '/privacy/ill_empty_state.svg', alt: "TBD" },
        emptyStateBreaches: { link: this.configService.config.pathImages + '/privacy/ill_empty_state_breaches.svg', alt: "TBD" },
        errorState: { link: this.configService.config.pathImages + '/privacy/ill_error_state.svg', alt: "TBD" },
        identityMonitorOn: { link: this.configService.config.pathImages + '/privacy//ill_continuous_monitor-on.png', alt: "" },
        identityMonitorOff: { link: this.configService.config.pathImages + '/privacy/ill_continuous_monitor-off.png', alt: "" },
        identityScore: { link: this.configService.config.pathImages + 'privacy/ill_identity_score.svg', alt: "" },
        feedback: {link: this.configService.config.pathImages + 'privacy/ill_feedback.svg', alt: "" }
    };

    icons = {
        footPrintDefaultCategory: { link: this.configService.config.pathIcons + 'privacy/services_default_logo.svg', alt: "" },
        footprintMobileCategory: { link: this.configService.config.pathIcons + 'privacy/mobile_directory_logo.svg', alt: "" },
    }

    aboutModalsContent = {
        activity: {
            type: 'simple',
            illustration: this.images.eventHistory.link,
            title: 'privacy.about.activity.title',
            description: 'privacy.about.activity.description',
            iUnderstandBtnText: 'privacy.aboutModal.close.cta'
        },
        privacyScore: {
            type: 'simple',
            illustration: this.images.identityScore.link,
            title: 'privacy.about.privacy.score.title',
            description: 'privacy.about.privacy.score.description',
            iUnderstandBtnText: 'privacy.aboutModal.privacy.score.cta',
            extraDetails: {
                title: 'privacy.about.privacy.score.details.headline',
                items: [
                    { icon: 'graph', text: 'privacy.about.privacy.score.details.first.hint' },
                    { icon: 'shield-protected', text: 'privacy.about.privacy.score.details.second.hint' },
                    { icon: 'shield-tips', text: 'privacy.about.privacy.score.details.third.hint' }
                ]
            }
        },
        identityMonitor: {
            type: 'simple',
            illustration: this.images.identityMonitor.link,
            title: 'privacy.about.identityMonitor.title',
            description: 'privacy.about.identityMonitor.description',
            iUnderstandBtnText: 'privacy.aboutModal.close.cta'
        },
        footprint: {
            type: 'simple',
            illustration: this.images.digitalFootprint.link,
            title: 'privacy.about.public.title',
            //description: this.translateService.instant('privacy.about.public.description', {accurateIcon: '<i class=\'connect_icon_check color-success mx-1 \'></i>', inaccurateIcon: '<i class=\'connect_icon_close color-shade-60 b2 ml-1 \'></i>'}),
            description: 'privacy.about.public.description',
            iUnderstandBtnText: 'privacy.aboutModal.close.cta'
        },
        breaches: {
            type: 'simple',
            illustration: this.images.dataBreaches.link,
            title: 'privacy.about.breaches.title',
            //description: this.translateService.instant('privacy.about.breaches.description', {link_icon: '<i class=\'connect_icon_external_link color-primary b2 mx-1 \'></i>', done_icon: '<i class=\'connect_icon_success_box color-success b2 ml-1 \'></i>'}),
            description: 'privacy.about.breaches.description',
            iUnderstandBtnText: 'privacy.aboutModal.close.cta'
        },
        impersonations: {
            type: 'simple',
            illustration: this.images.impersonations.link,
            title: 'privacy.about.impersonations.title',
            //description: this.translateService.instant('privacy.about.impersonations.description', {link_icon: '<i class=\'connect_icon_external_link color-primary b2 mx-1 \'></i>', done_icon: '<i class=\'connect_icon_success_box color-success b2 ml-1 \'></i>'}),
            description: 'privacy.about.impersonations.description',
            iUnderstandBtnText: 'privacy.aboutModal.close.cta'
        },
        education: {
            type: 'simple',
            illustration: this.images.education.link,
            title: 'privacy.about.education.title',
            description: 'privacy.about.education.description',
            iUnderstandBtnText: 'privacy.aboutModal.close.cta'
        },
        dataMonitor: {
            type: 'simple',
            illustration: this.images.dataMonitoring.link,
            title: 'privacy.about.dataMonitor.title',
            description: 'privacy.about.dataMonitor.description',
            iUnderstandBtnText: 'privacy.aboutModal.close.cta'
        },
        threatConfirmed_record: {
            type: 'withHideBtn',
            illustration: this.configService.config.pathImages + 'privacy/ill_validate@2x.png',
            title: 'privacy.confirm.record.title',
            description: 'privacy.confirm.record.description',
            cancelBtnText: 'privacy.onboarding.cancel2',
            ctaBtnText: 'privacy.confirm.record.cta'
        },
        threatConfirmed_impersonation: {
            type: 'withHideBtn',
            illustration: this.images.impersonations.link,
            title: 'privacy.confirm.impersonation.title',
            description: 'privacy.confirm.impersonation.description',
            cancelBtnText: 'privacy.onboarding.cancel2',
            ctaBtnText: 'privacy.confirm.impersonation.cta'
        },
        showImpersonationInfo: {
            type: 'withHideBtn',
            illustration: this.images.impersonations.link,
            title: 'privacy.showImpersonationInfo.title',
            description: 'privacy.showImpersonationInfo.description',
            cancelBtnText: 'privacy.onboarding.cancel2',
            ctaBtnText: 'privacy.showImpersonationInfo.cta'
        },
        confirmBreachAction: {
            type: 'withHideBtn',
            illustration: this.configService.config.pathImages + 'privacy/ill_validate@2x.png',
            title: 'privacy.confirmBreachAction.title',
            description: 'privacy.confirmBreachAction.description',
            cancelBtnText: 'privacy.onboarding.cancel2',
            ctaBtnText: 'privacy.confirmBreachAction.cta'
        },
        confirmUndoBreachAction: {
            type: 'withHideBtn',
            illustration: this.configService.config.pathImages + 'privacy/ill_validate@2x.png',
            title: 'privacy.confirmUndoBreachAction.title',
            description: 'privacy.confirmUndoBreachAction.description',
            cancelBtnText: 'privacy.onboarding.cancel2',
            ctaBtnText: 'privacy.confirmUndoBreachAction.cta'
        },
        confirmImpersonationAction: {
            type: 'withHideBtn',
            illustration: this.images.impersonations.link,
            title: 'privacy.confirmImpersonationAction.title',
            description: 'privacy.confirmImpersonationAction.description',
            cancelBtnText: 'privacy.onboarding.cancel2',
            ctaBtnText: 'privacy.confirmImpersonationAction.cta'
        },
        showLinkInfo: {
            type: 'withHideBtn',
            illustration: this.configService.config.pathImages + 'privacy/ill_validate@2x.png',
            title: 'privacy.showLinkInfo.title',
            description: 'privacy.showLinkInfo.description',
            cancelBtnText: 'privacy.onboarding.cancel2',
            ctaBtnText: 'privacy.showLinkInfo.cta'
        },
        validateMetadataInfo: {
            type: 'withHideBtn',
            illustration: this.configService.config.pathImages + 'privacy/ill_validate@2x.png',
            title: 'privacy.validateMetadataInfo.title',
            description: 'privacy.validateMetadataInfo.description',
            cancelBtnText: 'privacy.onboarding.cancel2',
            ctaBtnText: 'privacy.validateMetadataInfo.cta'
        }
    }

    maxValidatedMetadata = {
        phones: 3,
        emails: 5
    };

    // monitored data
    monitoredData = {
        names: MetadataType.NAMES,
        emails: MetadataType.EMAILS,
        phones: MetadataType.PHONES
    };

    monitoredDataInfo() {
        return {
            [this.monitoredData.names]: {
                label: 'privacy.dataMonitor.name.label',
                iconUrl: this.configService.config.pathImages + 'privacy/data-monitor/metadata-name.svg',
                maxValidations: 1,
            },
            [this.monitoredData.emails]: {
                label: 'privacy.dataMonitor.email.label',
                iconUrl: this.configService.config.pathImages + 'privacy/data-monitor/metadata-email.svg',
                maxValidations: 10,
            },
            [this.monitoredData.phones]: {
                label: 'privacy.dataMonitor.phone.label',
                iconUrl: this.configService.config.pathImages + 'privacy/data-monitor/metadata-phone.svg',
                maxValidations: 5,
            }
        }
    };

    educationArticles = {
        breaches: {
            id: 'breaches',
            topic: 'breach',
            staticTag: 'breaches_education_static',
            infoTags: {
                exposure: 'breaches_exposed_info',
                community: 'breaches_community_info',
                actions: 'breaches_actions_info',
                evolution: 'breaches_evolution_info'
            }
        },
        digital_footprint: {
            id: 'digital_footprint',
            topic: 'exposure',
            staticTag: 'footprint_education_static',
            infoTags: {
                exposure: 'footprint_exposed_info',
                community: 'footprint_community_info',
                validation: 'footprint_accuracy_info',
                evolution: 'footprint_evolution_info'
            }
        },
        impersonations: {
            id: 'impersonations',
            topic: 'impersonation',
            staticTag: 'impersonations_education_static',
            infoTags: {
                profile: 'impersonations_profiles_info',
                progress: 'impersonations_actions_info'
            }
        },
        remediations: {
            id: 'remediations',
            topic: 'remediation'
        }
    };

    educationArticlesTags = {
        footprintExposedInfo: 'footprint_exposed_info',
        footprintCommunityInfo: 'footprint_community_info',
        footprintAccuracyInfo: 'footprint_accuracy_info',
        footprintEvolutionInfo: 'footprint_evolution_info',
        footprintEducationStatic: 'footprint_education_static',
        breachesExposedInfo: 'breaches_exposed_info',
        breachesCommunityInfo: 'breaches_community_info',
        breachesActionsInfo: 'breaches_actions_info',
        breachesEvolutionInfo: 'breaches_evolution_info',
        breachesEducationStatic: 'breaches_education_static',
        impersonationsProfilesInfo: 'impersonations_profiles_info',
        impersonationsActionsInfo: 'impersonations_actions_info',
        impersonationsEducationStatic: 'impersonations_education_static'
    };

    educationArticlesTagsGrouped = {
        digital_footprint: [
            this.educationArticlesTags.footprintExposedInfo,
            this.educationArticlesTags.footprintCommunityInfo,
            this.educationArticlesTags.footprintAccuracyInfo,
            this.educationArticlesTags.footprintEvolutionInfo,
            this.educationArticlesTags.footprintEducationStatic
        ],
        breaches: [
            this.educationArticlesTags.breachesExposedInfo,
            this.educationArticlesTags.breachesCommunityInfo,
            this.educationArticlesTags.breachesActionsInfo,
            this.educationArticlesTags.breachesEvolutionInfo,
            this.educationArticlesTags.breachesEducationStatic
        ],
        impersonations: [
            this.educationArticlesTags.impersonationsProfilesInfo,
            this.educationArticlesTags.impersonationsActionsInfo,
            this.educationArticlesTags.impersonationsEducationStatic
        ]
    };

    educationArticlesOffset = 10;

    //#region dataclasses
    dataClasses = {
        decrypted_password: "decrypted_password",
        password: "password",
        email: "email",
        ip_address: "ip_address",
        phone: "phone",
        location: "location",
        encrypted_password: "encrypted_password",
        clear_password: "clear_password",
        username: "username",
        first_name: "first_name",
        name: "name",
        last_name: "last_name",
        career_level: "career_level",
        biometric_data: "biometric_data",
        chat_logs: "chat_logs",
        fitness_levels: "fitness_levels",
        sexual_fetishes: "sexual_fetishes",
        astrological_signs: "astrological_signs",
        parenting_plans: "parenting_plans",
        homepage_urls: "homepage_urls",
        url: "url",
        nationalities: "nationalities",
        financial_investments: "financial_investments",
        nickname: "nickname",
        bios: "bios",
        job_applications: "job_applications",
        login_histories: "login_histories",
        pins: "pins",
        survey_results: "survey_results",
        work_experience: "work_experience",
        employer_info: "employer_info",
        smoking_habits: "smoking_habits",
        drug_habits: "drug_habits",
        salutations: "salutations",
        avatars: "avatars",
        personal_health_data: "personal_health_data",
        device_serial_numbers: "device_serial_numbers",
        family_members_names: "family_members_names",
        donations_political: "donations_political",
        employment_status: "employment_status",
        contacts: "contacts",
        travel_habits: "travel_habits",
        licence_plates: "licence_plates",
        audio_recordings: "audio_recordings",
        reward_program_balances: "reward_program_balances",
        personal_descriptions: "personal_descriptions",
        physical_attributes: "physical_attributes",
        mnemonic_phrases: "mnemonic_phrases",
        occupation: "occupation",
        political_views: "political_views",
        income_levels: "income_levels",
        beauty_ratings: "beauty_ratings",
        work_habits: "work_habits",
        age_group: "age_group",
        gender: "gender",
        time_zones: "time_zones",
        relationship_statuses: "relationship_statuses",
        account_balance: "account_balance",
        professional_skills: "professional_skills",
        support_tickets: "support_tickets",
        living_costs: "living_costs",
        sms_messages: "sms_messages",
        imsi_numbers: "imsi_numbers",
        places_of_birth: "places_of_birth",
        other_social_media_profiles: "other_social_media_profiles",
        private_messages: "private_messages",
        partial_dob: "partial_dob",
        device_usage_tracking_data: "device_usage_tracking_data",
        passport_numbers: "passport_numbers",
        browser_user_agent_details: "browser_user_agent_details",
        encrypted_keys: "encrypted_keys",
        credit_card: "credit_card",
        donations_to_charity: "donations_to_charity",
        car_info: "car_info",
        age: "age",
        auth_tokens: "auth_tokens",
        utility_bills: "utility_bills",
        job_titles: "job_titles",
        instant_messenger_id: "instant_messenger_id",
        drinking_habits: "drinking_habits",
        personal_interests: "personal_interests",
        home_ownership_statuses: "home_ownership_statuses",
        partial_credit_card_data: "partial_credit_card_data",
        spouses_names: "spouses_names",
        recovery_email_addresses: "recovery_email_addresses",
        browsing_histories: "browsing_histories",
        email_messages: "email_messages",
        education_levels: "education_levels",
        eating_habits: "eating_habits",
        buying_preferences: "buying_preferences",
        credit_card_cvv: "credit_card_cvv",
        purchasing_habits: "purchasing_habits",
        historical_passwords: "historical_passwords",
        apps_installed_on_devices: "apps_installed_on_devices",
        car_ownership_statuses: "car_ownership_statuses",
        driver_s_license: "driver_s_license",
        credit_status_information: "credit_status_information",
        security_questions_and_answers: "security_questions_and_answers",
        photos: "photos",
        website_activity: "website_activity",
        purchases: "purchases",
        home_loan_information: "home_loan_information",
        password_hints: "password_hints",
        grades: "grades",
        bank_account_numbers: "bank_account_numbers",
        user_statuses: "user_statuses",
        ethnicities: "ethnicities",
        deceased_date: "deceased_date",
        net_worths: "net_worths",
        profile_photos: "profile_photos",
        device_information: "device_information",
        financial_transactions: "financial_transactions",
        mothers_maiden_names: "mothers_maiden_names",
        password_strengths: "password_strengths",
        races: "races",
        customer_feedback: "customer_feedback",
        mac_address: "mac_address",
        payment_histories: "payment_histories",
        ssn: "ssn",
        government_issued_id: "government_issued_id",
        payment_methods: "payment_methods",
        imei_numbers: "imei_numbers",
        religions: "religions",
        cellular_network_names: "cellular_network_names",
        deceased_statuses: "deceased_statuses",
        family_structure: "family_structure",
        marital_status: "marital_status",
        social_media_connections: "social_media_connections",
        customer_interactions: "customer_interactions",
        tax_records: "tax_records",
        sexual_orientation: "sexual_orientation",
        medical_insurance: "medical_insurance",
        languages: "languages",
        dob: "dob",
        medical_info: "medical_info",
        medical_ids: "medical_ids",
        charliecard_numbers: "charliecard_numbers",
        facebook_profile: "facebook_profile",
        it_systems: "it_systems",
        star_alliance: "star_alliance",
        telegram_ids: "telegram_ids",
        google_ids: "google_ids",
        twitter_ids: "twitter_ids",
        vrms: "vrms",
        tax_forms: "tax_forms",
        wordpress_login_location_urls: "wordpress_login_location_urls",
        service_info: "service_info",
        account_information: "account_information",
        account_numbers: "account_numbers",
        activity_prescription_forms: "activity_prescription_forms",
        addiction_history: "addiction_history",
        dates_of_admission: "dates_of_admission",
        air_india_frequent_flyer_data: "air_india_frequent_flyer_data",
        configuration_info: "configuration_info",
        security_info: "security_info",
        billing_information: "billing_information",
        case_numbers_related_to_procedures: "case_numbers_related_to_procedures",
        claim_information: "claim_information",
        compensation_amounts: "compensation_amounts",
        computer_systems: "computer_systems",
        contract_information: "contract_information",
        dates_of_service: "dates_of_service",
        dates_and_locations_of_treatment: "dates_and_locations_of_treatment",
        deduction_information: "deduction_information",
        departments_of_service: "departments_of_service",
        dependents_names: "dependents_names",
        device_access_information: "device_access_information",
        electronic_signature: "electronic_signature",
        other_documents: "other_documents",
        domestic_violence_reports: "domestic_violence_reports",
        donations_to_militia: "donations_to_militia",
        employment_info: "employment_info",
        enrollment_information: "enrollment_information",
        faxed_documents: "faxed_documents",
        financial_info: "financial_info",
        fingerprints: "fingerprints",
        guarantor: "guarantor",
        height: "height",
        weight: "weight",
        human_resource_files: "human_resource_files",
        insurance_carrier_names: "insurance_carrier_names",
        insurance_number: "insurance_number",
        insurance_info: "insurance_info",
        insurance_status: "insurance_status",
        insurer_details: "insurer_details",
        library_card_numbers: "library_card_numbers",
        licence_plate: "licence_plate",
        subscription_ids: "subscription_ids",
        members_past_sales_transaction_records: "members_past_sales_transaction_records",
        order_information: "order_information",
        passport: "passport",
        personal_information: "personal_information",
        user_website_url: "user_website_url",
        polygraph_test_results: "polygraph_test_results",
        programs_and_types_of_services: "programs_and_types_of_services",
        progress_notes: "progress_notes",
        project_details: "project_details",
        proprietary_applications: "proprietary_applications",
        provider_information: "provider_information",
        provider_names: "provider_names",
        reasons_for_admission: "reasons_for_admission",
        resume_reference_request: "resume_reference_request",
        salary_information: "salary_information",
        schools: "schools",
        service_dates: "service_dates",
        shipping_and_tracking_numbers: "shipping_and_tracking_numbers",
        staffing_payment_information: "staffing_payment_information",
        statutory_benefits_information: "statutory_benefits_information",
        student_records: "student_records",
        tax_ids: "tax_ids",
        termination_records: "termination_records",
        ticket_information: "ticket_information",
        timestamps: "timestamps",
        timestamps_for_logins: "timestamps_for_logins",
        type_of_service: "type_of_service",
        visa_info: "visa_info",
        disability_info: "disability_info",
        withholding: "withholding",
        aadhaar_number: "aadhaar_number",
        account_creation_date: "account_creation_date",
        bank_balances: "bank_balances",
        bank_communications: "bank_communications",
        bank_name: "bank_name",
        benefits_information: "benefits_information",
        booking_leads: "booking_leads",
        cpf: "cpf",
        city: "city",
        clearbalance_loan_number_and_balance: "clearbalance_loan_number_and_balance",
        compensations: "compensations",
        contact_details: "contact_details",
        cover_letters: "cover_letters",
        credit_card_apllications: "credit_card_apllications",
        demographic_information: "demographic_information",
        driver_license: "driver_license",
        education_info: "education_info",
        expiry_dates: "expiry_dates",
        facebook_id: "facebook_id",
        family_members: "family_members",
        frequent_flyer_number: "frequent_flyer_number",
        id_cards: "id_cards",
        instagram_id: "instagram_id",
        insurance_id: "insurance_id",
        invoices: "invoices",
        linkedin_profile: "linkedin_profile",
        name_initial: "name_initial",
        national_cards_id: "national_cards_id",
        social_media_followers: "social_media_followers",
        social_media_following: "social_media_following",
        order_histories: "order_histories",
        personal_data: "personal_data",
        political_affiliation: "political_affiliation",
        purchasing_info: "purchasing_info",
        scan_reports: "scan_reports",
        scanned_checks: "scanned_checks",
        shipment_details: "shipment_details",
        signatures: "signatures",
        social_insurance_number: "social_insurance_number",
        other_social_media_ids: "other_social_media_ids",
        tax_documents: "tax_documents",
        tax_info: "tax_info",
        tax_payer_id: "tax_payer_id",
        termination_dates: "termination_dates",
        vehicle_identification_number: "vehicle_identification_number",
        work_history: "work_history",
        zip: "zip",
        all_files: "all_files",
        applications: "applications",
        bank_info: "bank_info",
        chart_numbers: "chart_numbers",
        chat_messages: "chat_messages",
        citizenship: "citizenship",
        immigration_status: "immigration_status",
        claim_forms: "claim_forms",
        compensation_claims: "compensation_claims",
        compensation_info: "compensation_info",
        computer_network: "computer_network",
        credits_with_the_company: "credits_with_the_company",
        date_and_reason_for_exams: "date_and_reason_for_exams",
        date_of_service: "date_of_service",
        car_accidents_history: "car_accidents_history",
        locations_of_services: "locations_of_services",
        delivery_addresses: "delivery_addresses",
        digital_and_technology_systems: "digital_and_technology_systems",
        earnings: "earnings",
        emergency_contact: "emergency_contact",
        mothers_maiden_name: "mothers_maiden_name",
        online_services: "online_services",
        parent_information: "parent_information",
        parking_tickets: "parking_tickets",
        card_information: "card_information",
        payment_records: "payment_records",
        payroll: "payroll",
        personnel_records: "personnel_records",
        prize_amounts: "prize_amounts",
        product_schematics: "product_schematics",
        profile_descriptions: "profile_descriptions",
        property_value: "property_value",
        referral_id_numbers: "referral_id_numbers",
        financial_status: "financial_status",
        session_ids: "session_ids",
        sexual_assault_reports: "sexual_assault_reports",
        source_code: "source_code",
        town_hard_drives: "town_hard_drives",
        training_documents: "training_documents",
        car_registration_numbers: "car_registration_numbers",
        video: "video",
        wages: "wages",
        company: "company",
        nid: "nid",
        duns: "duns",
        state: "state",
        unknown: 'unknown'
    };

    dataClassesCategories = {
        email: new Set([this.dataClasses.email, this.dataClasses.username]),
        password: new Set([this.dataClasses.password_hints, this.dataClasses.password, this.dataClasses.security_questions_and_answers, this.dataClasses.encrypted_password]),
        money: new Set([this.dataClasses.pins, this.dataClasses.payment_histories, this.dataClasses.payment_methods]),
        address: new Set([this.dataClasses.location, this.dataClasses.delivery_addresses])
    };

    dataClassesDefaultIcon = 'chevron-right-circled';
    dataClassesIcons = {
        bios: [
            this.dataClasses.bios,
            this.dataClasses.deceased_date,
            this.dataClasses.deceased_statuses,
            this.dataClasses.personal_descriptions,
            this.dataClasses.personal_health_data,
            this.dataClasses.physical_attributes,
            this.dataClasses.photos,
            this.dataClasses.profile_photos,
            this.dataClasses.citizenship
        ],
        names: [
            this.dataClasses.passport_numbers,
            this.dataClasses.name,
            this.dataClasses.first_name,
            this.dataClasses.last_name,
            this.dataClasses.nickname,
            this.dataClasses.name_initial
        ],
        usernames: [
            this.dataClasses.username
        ],
        credit_status_info: [
            this.dataClasses.account_balance
        ],
        financial_investments: [
            this.dataClasses.financial_investments,
            this.dataClasses.financial_transactions,
            this.dataClasses.living_costs
        ],
        cryptocurrency: [],
        home_ownership_status: [
            this.dataClasses.home_ownership_statuses
        ],
        income_levels: [
            this.dataClasses.income_levels,
            this.dataClasses.reward_program_balances,
            this.dataClasses.wages,
            this.dataClasses.payroll
        ],
        net_worths: [
            this.dataClasses.net_worths
        ],
        payment_histories: [
            this.dataClasses.buying_preferences,
            this.dataClasses.payment_histories,
            this.dataClasses.purchases,
            this.dataClasses.purchasing_habits
        ],
        partial_credit_data: [
            this.dataClasses.credit_card_cvv
        ],
        payment_methods: [
            this.dataClasses.payment_methods,
            this.dataClasses.utility_bills
        ],
        email_addresses: [
            this.dataClasses.email,
            this.dataClasses.email_messages
        ],
        dates_of_birth: [
            this.dataClasses.age,
            this.dataClasses.astrological_signs
        ],
        education_level: [
            this.dataClasses.grades
        ],
        ethnicities: [
            this.dataClasses.races,
            this.dataClasses.nationalities
        ],
        genders: [
            this.dataClasses.gender
        ],
        religions: [
            this.dataClasses.religions
        ],
        spoken_languages: [
            this.dataClasses.languages
        ],
        employers: [
            this.dataClasses.work_experience,
            this.dataClasses.work_habits,
            this.dataClasses.employer_info,
            this.dataClasses.employment_status,
            this.dataClasses.job_applications,
            this.dataClasses.professional_skills
        ],
        job_titles: [
            this.dataClasses.career_level,
            this.dataClasses.job_applications,
            this.dataClasses.job_titles
        ],
        occupations: [
            this.dataClasses.occupation,
            this.dataClasses.car_info
        ],
        family_structure: [
            this.dataClasses.spouses_names,
            this.dataClasses.family_members,
            this.dataClasses.family_structure,
            this.dataClasses.parenting_plans
        ],
        marital_statuses: [
            this.dataClasses.marital_status,
            this.dataClasses.relationship_statuses
        ],
        ip_addresses: [
            this.dataClasses.ip_address,
            this.dataClasses.mac_address
        ],
        address_physical: [
            this.dataClasses.places_of_birth,
            this.dataClasses.location,
            this.dataClasses.city,
            this.dataClasses.state
        ],
        pass_hashed: [
            this.dataClasses.auth_tokens
        ],
        pass_hints: [
            this.dataClasses.mnemonic_phrases
        ],
        pass: [
            this.dataClasses.password,
            this.dataClasses.clear_password,
            this.dataClasses.encrypted_password,
            this.dataClasses.decrypted_password
        ],
        pins: [
            this.dataClasses.pins
        ],
        security_questions: [
            this.dataClasses.security_questions_and_answers
        ],
        phone_numbers: [
            this.dataClasses.contacts,
            this.dataClasses.cellular_network_names,
            this.dataClasses.sms_messages,
            this.dataClasses.phone,
            this.dataClasses.imsi_numbers,
            this.dataClasses.imei_numbers
        ],
        personal_interests: [
            this.dataClasses.donations_to_charity,
            this.dataClasses.chat_logs,
            this.dataClasses.drinking_habits,
            this.dataClasses.eating_habits,
            this.dataClasses.drug_habits,
            this.dataClasses.smoking_habits,
            this.dataClasses.sexual_fetishes,
            this.dataClasses.sexual_orientation,
            this.dataClasses.customer_feedback,
            this.dataClasses.customer_interactions,
            this.dataClasses.fitness_levels,
            this.dataClasses.personal_interests,
            this.dataClasses.donations_political,
            this.dataClasses.political_views,
            this.dataClasses.private_messages,
            this.dataClasses.time_zones,
            this.dataClasses.travel_habits,
            this.dataClasses.support_tickets,
            this.dataClasses.astrological_signs
        ],
        social_profiles: [
            this.dataClasses.avatars,
            this.dataClasses.beauty_ratings,
            this.dataClasses.other_social_media_profiles,
            this.dataClasses.user_statuses
        ],
        user_website_urls: [
            this.dataClasses.device_information,
            this.dataClasses.homepage_urls
        ],
        website_activity: [
            this.dataClasses.audio_recordings,
            this.dataClasses.website_activity,
            this.dataClasses.survey_results
        ]
    };

    breachesRiskCategories = {
        tokens: [this.dataClasses.auth_tokens],
        biometrics: [this.dataClasses.biometric_data],
        credit: [this.dataClasses.partial_credit_card_data, this.dataClasses.credit_card, this.dataClasses.pins, this.dataClasses.card_information],
        email_messages: [this.dataClasses.email_messages],
        home_address: [this.dataClasses.zip, this.dataClasses.delivery_addresses, this.dataClasses.shipment_details],
        id: [this.dataClasses.aadhaar_number, this.dataClasses.government_issued_id, this.dataClasses.medical_ids, this.dataClasses.tax_ids,
            this.dataClasses.driver_s_license, this.dataClasses.insurance_number, this.dataClasses.insurance_id,
            this.dataClasses.driver_license, this.dataClasses.nid, this.dataClasses.passport, this.dataClasses.tax_payer_id,
            this.dataClasses.vehicle_identification_number, this.dataClasses.id_cards,
            this.dataClasses.frequent_flyer_number, this.dataClasses.car_registration_numbers, this.dataClasses.licence_plate],
        job_company: [this.dataClasses.company, this.dataClasses.job_titles, this.dataClasses.employer_info, this.dataClasses.career_level, this.dataClasses.work_history,
            this.dataClasses.bios, this.dataClasses.occupation, this.dataClasses.employment_status, this.dataClasses.professional_skills,
            this.dataClasses.work_experience, this.dataClasses.job_applications],
        messenger_chats: [this.dataClasses.private_messages, this.dataClasses.chat_logs],
        online_financial: [this.dataClasses.bank_account_numbers, this.dataClasses.insurance_info, this.dataClasses.purchases,
            this.dataClasses.account_balance, this.dataClasses.prize_amounts, this.dataClasses.payment_histories, this.dataClasses.account_numbers,
            this.dataClasses.reward_program_balances, this.dataClasses.order_information, this.dataClasses.credit_status_information,
            this.dataClasses.home_loan_information, this.dataClasses.income_levels, this.dataClasses.financial_transactions,
            this.dataClasses.salary_information, this.dataClasses.payment_methods, this.dataClasses.financial_investments,
            this.dataClasses.net_worths, this.dataClasses.compensations, this.dataClasses.tax_info, this.dataClasses.compensation_info,
            this.dataClasses.billing_information, this.dataClasses.tax_records, this.dataClasses.benefits_information,
            this.dataClasses.payroll, this.dataClasses.invoices, this.dataClasses.purchasing_info, this.dataClasses.tax_documents,
            this.dataClasses.financial_info, this.dataClasses.bank_balances, this.dataClasses.bank_communications,
            this.dataClasses.bank_info, this.dataClasses.account_creation_date, this.dataClasses.bank_name, this.dataClasses.tax_forms,
            this.dataClasses.clearbalance_loan_number_and_balance, this.dataClasses.compensation_claims, this.dataClasses.credits_with_the_company],
        passport: [this.dataClasses.passport_numbers],
        media: [this.dataClasses.photos, this.dataClasses.profile_photos, this.dataClasses.video],
        sms_messages: [this.dataClasses.sms_messages],
        ssn: [this.dataClasses.ssn, this.dataClasses.national_cards_id],
        birth_date: [this.dataClasses.dob, this.dataClasses.partial_dob, this.dataClasses.age_group, this.dataClasses.age],
        device: [this.dataClasses.browser_user_agent_details, this.dataClasses.imei_numbers, this.dataClasses.mac_address,
            this.dataClasses.device_information, this.dataClasses.apps_installed_on_devices, this.dataClasses.cellular_network_names,
            this.dataClasses.imsi_numbers, this.dataClasses.device_serial_numbers, this.dataClasses.it_systems,
            this.dataClasses.computer_systems, this.dataClasses.digital_and_technology_systems, this.dataClasses.computer_network],
        email: [this.dataClasses.email],
        gender: [this.dataClasses.gender],
        historical_passwords: [this.dataClasses.historical_passwords],
        ip_address: [this.dataClasses.ip_address],
        location: [this.dataClasses.location, this.dataClasses.city, this.dataClasses.state, this.dataClasses.languages, this.dataClasses.time_zones,
            this.dataClasses.nationalities, this.dataClasses.demographic_information],
        name: [this.dataClasses.name, this.dataClasses.salutations, this.dataClasses.last_name, this.dataClasses.family_structure,
            this.dataClasses.contact_details, this.dataClasses.personnel_records, this.dataClasses.personal_descriptions,
            this.dataClasses.first_name, this.dataClasses.family_members_names, this.dataClasses.avatars,
            this.dataClasses.contacts, this.dataClasses.name_initial, this.dataClasses.personal_data,
            this.dataClasses.family_members, this.dataClasses.contract_information],
        passwords: [this.dataClasses.password, this.dataClasses.security_questions_and_answers, this.dataClasses.encrypted_password,
            this.dataClasses.decrypted_password, this.dataClasses.clear_password, this.dataClasses.password_hints,
            this.dataClasses.password_strengths],
        usernames: [this.dataClasses.username, this.dataClasses.nickname, this.dataClasses.instant_messenger_id,
            this.dataClasses.referral_id_numbers, this.dataClasses.facebook_id, this.dataClasses.twitter_ids, this.dataClasses.session_ids],
        social_media: [this.dataClasses.profile_descriptions, this.dataClasses.social_media_connections,
            this.dataClasses.other_social_media_profiles, this.dataClasses.social_media_followers, this.dataClasses.social_media_following, this.dataClasses.linkedin_profile],
        phone: [this.dataClasses.phone],
        medical: [this.dataClasses.medical_insurance, this.dataClasses.medical_info, this.dataClasses.personal_health_data],
        website_activity: [this.dataClasses.website_activity]
    };

    dataClassesCategoriesOrder = {
        email: 1,
        password: 2,
        money: 3,
        address: 4,
        unknown: 5
    };
    //#endregion

    lastStepsOnboarding = new Set(['1.5.1', '1.5.2']);

    breachesRiskTypes = {
        email: 'email',
        pass: 'password',
        default: 'default'
    };

    riskMapTypes = {
        email: 'email',
        email_name: 'email_name',
        ip_address: 'ip_address',
        phone_number: 'phone_number',
        phone_number_and_name: 'phone_number_and_name',
        email_and_decoded_password: 'email_and_decoded_password',
        email_and_encoded_password: 'email_and_encoded_password',
        email_and_plaintext_password: 'email_and_plaintext_password',
        email_password_2fa: 'email_password_2fa'
    };

    articlePath = '/privacy/education/article/';

    languageGroups = {
        'en': this.valuesService.languages.en_US.id.split('_')[0],
        'fr': this.valuesService.languages.fr_FR.id.split('_')[0],
        'de': this.valuesService.languages.de_DE.id.split('_')[0],
    };

    languagesCategories = {
        [this.languageGroups.en]:
            new Set([this.valuesService.languages.de_DE.id,
                        this.valuesService.languages.en_AU.id,
                        this.valuesService.languages.en_GB.id,
                        this.valuesService.languages.en_US.id,
                        this.valuesService.languages.el_GR.id,
                        this.valuesService.languages.es_ES.id,
                        this.valuesService.languages.it_IT.id,
                        this.valuesService.languages.ko_KR.id,
                        this.valuesService.languages.nl_NL.id,
                        this.valuesService.languages.pl_PL.id,
                        this.valuesService.languages.pt_PT.id,
                        this.valuesService.languages.pt_BR.id,
                        this.valuesService.languages.ro_RO.id,
                        this.valuesService.languages.cs_CZ.id,
                        this.valuesService.languages.vi_VN.id,
                        this.valuesService.languages.ar_SA.id,
                        this.valuesService.languages.ja_JP.id,
                        this.valuesService.languages.tr_TR.id,
                        this.valuesService.languages.ru_RU.id,
                        this.valuesService.languages.hu_HU.id,
                        this.valuesService.languages.sv_SE.id,
                        this.valuesService.languages.th_TH.id,
                    ]),
        [this.languageGroups.fr]:
            new Set([this.valuesService.languages.fr_CA.id,
                        this.valuesService.languages.fr_FR.id
                    ]),
        [this.languageGroups.de]:
                    new Set([this.valuesService.languages.de_DE.id])
    };

    potentialDigitalFootprintClasses = {
        ads: [this.personalDataCategories.preferences, this.personalDataCategories.advertisingProfile, this.personalDataCategories.demographicData, this.personalDataCategories.knowledge, this.personalDataCategories.search],
        advice: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.privateMessages, this.personalDataCategories.contentGenerated],
        alcohol: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.demographicData, this.personalDataCategories.financial, this.personalDataCategories.purchases, this.personalDataCategories.shipping],
        astrology: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.demographicData],
        auto: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        blogs: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.contentGenerated, this.personalDataCategories. comments],
        business: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        computersandsoftware: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        drugs: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.identificationData, this.personalDataCategories.financial, this.personalDataCategories.purchases, this.personalDataCategories.shipping],
        education: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        entertainment: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        filesharing: [this.personalDataCategories.authentication, this.personalDataCategories.contentGenerated, this.personalDataCategories.subscription],
        financial: [this.personalDataCategories.authentication, this.personalDataCategories.financial, this.personalDataCategories.identificationData, this.personalDataCategories.documents, this.personalDataCategories.transactions],
        food: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        gambling: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.financial, this.personalDataCategories.identificationData, this.personalDataCategories.topUp],
        games: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.subscription],
        government: [this.personalDataCategories.authentication, this.personalDataCategories.documents, this.personalDataCategories.identificationData],
        hacking: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.privateMessages],
        hate: [this.personalDataCategories.preferences],
        health: [this.personalDataCategories.authentication, this.personalDataCategories.medical, this.personalDataCategories.identificationData, this.personalDataCategories.subscription],
        hobbies: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.demographicData],
        hosting: [this.personalDataCategories.authentication, this.personalDataCategories.purchases, this.personalDataCategories.subscription, this.personalDataCategories.contentGenerated],
        illegalactivities: [this.personalDataCategories.preferences],
        illegal: [this.personalDataCategories.preferences],
        im: [this.personalDataCategories.authentication, this.personalDataCategories.privateMessages, this.personalDataCategories.contentGenerated, this.personalDataCategories.preferences],
        jobsearch: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.jobEducation, this.personalDataCategories.comments, this.personalDataCategories.privateMessages, this.personalDataCategories.search],
        kids: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        lifestyle: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        lingerie: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        maturecontent: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        marijuana: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        misc: [this.personalDataCategories.preferences],
        narcotics: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        narcoticsgeneral: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.financial],
        news: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.comments],
        nudity: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        occult: [this.personalDataCategories.preferences],
        onlinedating: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.privateMessages, this.personalDataCategories.contentGenerated],
        onlinepay: [this.personalDataCategories.authentication, this.personalDataCategories.financial, this.personalDataCategories.identificationData],
        onlinephotos: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.contentGenerated, this.personalDataCategories.purchases, this.personalDataCategories.subscription],
        onlineshop: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.financial, this.personalDataCategories.demographicData, this.personalDataCategories.shipping, this.personalDataCategories.purchases, this.personalDataCategories.subscription],
        pets: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.demographicData, this.personalDataCategories.financial, this.personalDataCategories.purchases, this.personalDataCategories.shipping],
        pharmacy: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.demographicData, this.personalDataCategories.financial, this.personalDataCategories.purchases, this.personalDataCategories.shipping],
        porn: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.comments, this.personalDataCategories.subscription],
        piracy: [this.personalDataCategories.preferences],
        portals: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.comments],
        radiomusic: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.subscription, this.personalDataCategories.songs, this.personalDataCategories.comments, this.personalDataCategories.privateMessages, this.personalDataCategories.search],
        realestate: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.privateMessages, this.personalDataCategories.contentGenerated],
        religion: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.knowledge],
        searchengines: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.search],
        sextoys: [this.personalDataCategories.preferences],
        sexualcontent: [this.personalDataCategories.preferences],
        sexualeducation: [this.personalDataCategories.preferences],
        socialnetworks: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.demographicData, this.personalDataCategories.social, this.personalDataCategories.comments, this.personalDataCategories.privateMessages, this.personalDataCategories.knowledge, this.personalDataCategories.contentGenerated, this.personalDataCategories.search],
        society: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        sports: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.comments],
        suicide: [this.personalDataCategories.preferences],
        tabloids: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.comments],
        tobacco: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.demographicData, this.personalDataCategories.financial, this.personalDataCategories.purchases, this.personalDataCategories.shipping],
        timewasters: [this.personalDataCategories.authentication, this.personalDataCategories.preferences],
        travel: [this.personalDataCategories.authentication, this.personalDataCategories.identificationData, this.personalDataCategories.preferences, this.personalDataCategories.booking],
        videos: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.contentGenerated, this.personalDataCategories.videos, this.personalDataCategories.search],
        violentcartoons: [this.personalDataCategories.videos],
        weapons: [this.personalDataCategories.authentication, this.personalDataCategories.preferences, this.personalDataCategories.identificationData, this.personalDataCategories.purchases, this.personalDataCategories.shipping],
        webmail: [this.personalDataCategories.authentication, this.personalDataCategories.emails, this.personalDataCategories.contact],
        webproxy: [this.personalDataCategories.preferences, this.personalDataCategories.ip]
    }

    // TODO change articles lnks for de_DE
    articleLinks = {
        'aboutPages': {
            'breaches': this.articlePath.concat('_LamIyKx'),
            'impersonations': this.articlePath.concat('lYnLBvd7'),
            'footprint': this.articlePath.concat('SzwQaO8G')
        },
        'breaches': {
            [this.breachesRiskTypes.pass]: this.articlePath.concat('-93UhR53'),
            [this.breachesRiskTypes.email]: this.articlePath.concat('4PoO7CAB'),
            [this.breachesRiskTypes.default]: this.articlePath.concat('_LamIyKx')
        },
        'breachesRisk': {
            [this.languageGroups.en]: {
                tokens: this.articlePath.concat('fPOLp9Hwj'),
                biometrics: this.articlePath.concat('h50wAsvGY'),
                credit: this.articlePath.concat('KkZcGfuo3'),
                email_messages: this.articlePath.concat('ncbCAMnPP'),
                home_address: this.articlePath.concat('TygT9Mw8j'),
                id: this.articlePath.concat('zbdHhziFV'),
                job_company: this.articlePath.concat('gZENTAoJr'),
                messenger_chats: this.articlePath.concat('V2jfKK8Wm'),
                online_financial: this.articlePath.concat('JOxsiD1Fl'),
                passport: this.articlePath.concat('XG7xU3JyG'),
                media: this.articlePath.concat('9ECgRZxQs'),
                sms_messages: this.articlePath.concat('Otde-kJxP'),
                ssn: this.articlePath.concat('8eu1F0Q5W'),
                birth_date: this.articlePath.concat('WjGCxoXx-'),
                device: this.articlePath.concat('ALMibntX1'),
                email: this.articlePath.concat('Y-rEvDso9'),
                gender: this.articlePath.concat('3KM7IZB9_'),
                historical_passwords: this.articlePath.concat('6UGIP0CUc'),
                ip_address: this.articlePath.concat('uzk9i9OxN'),
                location: this.articlePath.concat('l46k4Z8os'),
                name: this.articlePath.concat('aYrVa5P2C'),
                passwords: this.articlePath.concat('bZAtEgir8'),
                usernames: this.articlePath.concat('LLeQBTyxz'),
                social_media: this.articlePath.concat('Gk96TdFzO'),
                phone: this.articlePath.concat('hw0uNMeB9'),
                medical: this.articlePath.concat('HBAGamvY4'),
            }
        },
        'riskMap': {
            [this.languageGroups.en]: {
                [this.riskMapTypes.email]: this.articlePath.concat('ncbCAMnPP'),
                [this.riskMapTypes.email_name]: this.articlePath.concat('2o6KHHPfM'),
                [this.riskMapTypes.ip_address]: this.articlePath.concat('uzk9i9OxN'),
                [this.riskMapTypes.phone_number]: this.articlePath.concat('hw0uNMeB9'),
                [this.riskMapTypes.phone_number_and_name]: this.articlePath.concat('hw0uNMeB9'),
                [this.riskMapTypes.email_and_decoded_password]: this.articlePath.concat('6UGIP0CUc'),
                [this.riskMapTypes.email_and_encoded_password]: this.articlePath.concat('6UGIP0CUc'),
                [this.riskMapTypes.email_and_plaintext_password]: this.articlePath.concat('6UGIP0CUc'),
                [this.riskMapTypes.email_password_2fa]: this.articlePath.concat('6UGIP0CUc'),
                'default': this.articlePath.concat('_LamIyKx')
            }
        },
        'impersonations': this.articlePath.concat('lYnLBvd7')
    };

    // will be changed in near refactorization along with articleLinks
    collectionArticleLink = this.articlePath.concat('nwniqypE0');

    staticLinks = {
        [this.languageGroups.en]: {
            'landingpage': 'https://www.bitdefender.com/solutions/digital-identity-protection.html',
            'renew_subscription': 'https://www.bitdefender.com/solutions/digital-identity-protection.html',
        },
        [this.languageGroups.fr]: {
            'landingpage': 'https://www.bitdefender.fr/solutions/digital-identity-protection.html',
            'renew_subscription': 'https://www.bitdefender.fr/solutions/digital-identity-protection.html',
        },
        [this.languageGroups.de]: {
            'landingpage': 'https://www.bitdefender.de/solutions/digital-identity-protection.html',
            'renew_subscription': 'https://www.bitdefender.de/solutions/digital-identity-protection.html',
        }
    };

    specificStaticLinks = {
        [this.valuesService.languages.en_GB.id]: {
            'landingpage': 'https://www.bitdefender.co.uk/solutions/digital-identity-protection.html',
            'renew_subscription': 'https://www.bitdefender.co.uk/solutions/digital-identity-protection.html',
        },
        [this.valuesService.languages.en_AU.id]: {
            'landingpage': 'https://www.bitdefender.com.au/solutions/digital-identity-protection.html',
            'renew_subscription': 'https://www.bitdefender.com.au/solutions/digital-identity-protection.html',
        }
    };
}

export enum SummaryType {
    EXPOSURE = 'exposure',
    BREACHES = 'breaches',
    BREACH = 'breach',
    IMPERSONATION = 'impersonation'
}

export enum RiskMapType {
    SIMPLE = 'simple',
    OVERVIEW = 'overview',
    EXPERT = 'expert',
}

export enum PrivacyEvents {
    UNDO_IMPERSONATION = 'undoImpersonation',
    UNDO_BREACH = 'undoBreach',
    UNDO_EXPOSURE = 'undoExposure',
    UPDATE_TOPIC = 'update_topic',
    VALIDATE_METADATA = 'validateMetadata',
    VALIDATE_METADATA_INFO = 'validateMetadataInfo',
    UPDATE_SERVICE_STATUS = 'updateServiceStatus',
    ONBOARDING_IS_OK = 'onboardingIsOk',
    IDENTITY_WAS_FOUND = 'identityWasFound',
    SUMMARY_RELOADED = 'activityExposureSummary_reloaded',
    RELOAD_ACTIVITY_LIST = 'reloadActivityList',
    RELOAD_ISSUES_LIST = 'reloadIssuesList',
    ISSUE_IS_FIXED = 'issueIsFixed',
    SET_PREV_PAGE_FOR_ABOUT = 'setPrevPageForAbout',
    MARK_IMPERSONATION_AS_RELEVANT = 'markImpersonationAsRelevant',
    MARK_IMPERSONATION_AS_IRRELEVANT = 'markImpersonationAsIrrelevant',
    CONFIRM_RECORD_CTA = 'confirmRecordCta',
    CONFIRM_IMPERSONATION_CTA = 'confirmImpersonationCta',
    CONFIRM_IMPERSONATION_ACTION = 'confirmImpersonationAction',
    CONFIRM_BREACH_ACTION = 'confirmBreachAction',
    CONFIRM_BREACH_ACTION_CANCEL = 'confirmBreachAction_cancel',
    MANUAL_SCAN_DONE = 'manual_scan_done',
    ISSUE_HAS_ONE_ACTION_FIXED = 'issueHasOneActionFixed',
    UNDO_IMPERSONATION_ACTION = 'undoImpersonationAction',
    UNDO_BREACH_ACTION = 'undoBreachAction',
    PARKED_IDENTITY = 'parkedIdentity',
    SUBMITTED_REQUEST = 'submittedForm',
    DELETED_IDENTITY = 'deletedIdentity',
    SUBMITTED_FEEDBACK = 'submittedFeedback',
    SUMMARY_EXPOSURE_READY = 'summaryExposureReady',
    SUMMARY_BREACHES_READY = 'summaryBreachesReady',
    ACTIVITY_LOADED = 'activityLoaded',
    SUBSCRIPTION_EXPIRED = 'subscriptionsExpired',
    DATA_WAS_VALIDATED_FROM_ACTIVITY_FOOTPRINT = "dataWasValidatedFromActivityFootprint" // - do not remove, used when data was validated in activity footprind wizzard
}

export enum PrivacyErrorCodes {
    DATABASE_ERROR = '32701',
    INVALID_PARAMS = '104',
    IDENTITY_EXISTS = '40011',
    NO_IDENTITY = '40012',
    INFO_NOT_FOUND = '40021',
    INFO_EXISTS = '40022',
    INFO_INVALID = '40023',
    INFO_VALIDATED = '40051', //inainte era 40031
    SEND_CODE_ERR = '40032',
    VALIDATION_CODE_INVALID = '40033',
    VALIDATION_CODE_EXPIRED = '40034',
    VALIDATION_ABUSE = '40035'
}