import { Injectable } from '@angular/core';
import { ValuesService } from "../../../values/values.service";
import { CookieService } from "ngx-cookie-service";
import { RenewalInfoService } from "../../requests/connect-renewal-info-service/connect-renewal-info.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize, map, skipWhile } from "rxjs/operators";
import { RenewalInfoModel } from '../../../../common/models/Services.model';
import { ConfigService } from '../../../../common/config/config.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentsService {

    subscriptions: RenewalInfoModel[];
    renewalCookie = false;
    markToUpdateBiSubs = true;

    private onlistSubscriptions$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    constructor(
        private valuesService: ValuesService,
        private cookieService: CookieService,
        private renewalInfoService: RenewalInfoService,
        private configService: ConfigService
    ) {}

    list(): Observable<any> {
        if (!this.configService.config.subscriptionsTabs.payments) {
            return of(this.subscriptions);
        }

        if (this.hasRenewalCookie()) {
            this.subscriptions = JSON.parse(sessionStorage.getItem(this.valuesService.cookieShowAr))['resp'];
            return of(this.subscriptions);
        }

        if (!this.markToUpdateBiSubs) {
            return of(this.subscriptions);
        }

        if (this.onlistSubscriptions$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistSubscriptions$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistSubscriptions$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.renewalInfoService.getUserSubscriptions().pipe(
                map(
                    resp => {
                        this.subscriptions = resp;

                        if (this.subscriptions.length >= 1) {
                            const currentDate = new Date();
                            const timeToExpire = new Date(currentDate.getTime() + this.valuesService.MILISECONDS_IN_A_WEEK);
                            this.cookieService.set(this.valuesService.cookieShowAr, 'true', timeToExpire, '/', "", true);
                            sessionStorage.setItem(this.valuesService.cookieShowAr, JSON.stringify({ resp: resp, expiry: timeToExpire.getTime() }));
                        } else {
                            const currentDate = new Date();
                            const timeToExpire = new Date(currentDate.getTime() + (this.valuesService.MILISECONDS_IN_AN_HOUR / 2));
                            this.cookieService.set(this.valuesService.cookieShowAr, 'false', timeToExpire, '/', "", true);
                            sessionStorage.setItem(this.valuesService.cookieShowAr, JSON.stringify({ resp: resp, expiry: timeToExpire.getTime() }));
                        }
                        this.markToUpdateBiSubs = false;
                        return of(this.subscriptions);
                    }
                ),
                catchError( err => {
                    this.markToUpdateBiSubs = true;
                    return of(err);
                }),
                finalize(() => {
                    // am pus asta aici ca sa nu mai apelez resetInProgressFlags in app.component
                    this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                })
            );
        }
    }

    getRenewalSubscriptions() {
        return this.subscriptions?.length ? this.subscriptions : [];
    }

    hasRenewalCookie() {
        let cookie = this.cookieService.get(this.valuesService.cookieShowAr);
        const storage = sessionStorage.getItem(this.valuesService.cookieShowAr);

        if (cookie === 'true' && storage) {
            return true;
        } else {
            return false;
        }

    }

    resetInProgressFlags() {
        this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
    }
}
